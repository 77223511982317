export const TAIGA_CUSTOM_VALIDATOR_ERRORS = {
    required: $localize`Enter a value`,
    max: ({ max }): string => 
        $localize`Max number ${max}`,
    min: ({ min }): string =>
        $localize`Min number ${min}`,
    maxlength: ({ requiredLength }: { requiredLength: string }): string =>
        $localize`Maximum length - ${requiredLength}`,
    minlength: ({ requiredLength }: { requiredLength: string }): string =>
        $localize`Minimum length - ${requiredLength}`,
    email: $localize`Enter a valid email`,
}