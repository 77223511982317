import { Injectable } from '@angular/core';

import { GroupItem, ListItem, ProjectItem } from 'src/app/core/models/list-item';
import { OGC } from 'src/app/core/models/ogc';

@Injectable({
    providedIn: 'root',
})
export class ZIndexService {

    constructor() {}

    recalculateProjectIndex(projects: ProjectItem<any>[], initialIndex: number): ProjectItem<any>[] {
        let accumulatedZIndex = initialIndex;
        
        const projectsProcessed = projects.slice().reverse().map((project, index) => {
            const currentProjectSize = project.children.length;
            const projectZIndex = accumulatedZIndex + index;

            const processedGroups = this.recalculateGroupIndex(project.children, projectZIndex);

            const recalculatedProject: ProjectItem<any> = {
                ...project,
                children: processedGroups,
                zIndex: projectZIndex
            };
            accumulatedZIndex += currentProjectSize;

            return recalculatedProject;
        });

        return projectsProcessed.reverse();
    }

    recalculateGroupIndex(groups: GroupItem<OGC>[], initialIndex: number): GroupItem<OGC>[] {
        let accumulatedZIndex = initialIndex;

        const groupsProcessed = groups.slice().reverse().map((group, index) => {
            const currentGroupsSize = group.children.length;
            const groupZIndex = accumulatedZIndex + index;

            const processedItems = this.recalculateItemIndex(group.children, groupZIndex);
            
            const recalculatedGroup: GroupItem<OGC> = {
                ...group, 
                children: processedItems, 
                zIndex: groupZIndex
            };
            accumulatedZIndex += currentGroupsSize;

            return recalculatedGroup;
        });

        return groupsProcessed.reverse();
    }

    recalculateItemIndex(listItems: ListItem<OGC>[], initialIndex: number): ListItem<OGC>[] {
        const minZIndex = initialIndex;

        const processedItems: ListItem<OGC>[] = listItems.slice().reverse().map((item, itemIndex) => {
            return { ...item, zIndex: minZIndex + itemIndex}
        });

        return processedItems.reverse();
    }
}