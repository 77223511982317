import { TuiLineDaysChart, TuiLineDaysChartHint } from "@taiga-ui/addon-charts";
import { TuiPreview } from "@taiga-ui/kit";
import { TuiLet } from "@taiga-ui/cdk";
import { Component, inject } from '@angular/core';
import { Select } from '@ngxs/store';
import { TuiRoot, TuiAlert, TuiDialog, TUI_DARK_MODE } from '@taiga-ui/core';
import { Observable, tap } from 'rxjs';
import { ThemeState } from './shared/state/theme.state';
import { BusyComponent } from './core/components/busy.component';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe, CommonModule } from '@angular/common';
import { httpInterceptorProviders } from "./core/interceptors/http-interceptor-providers";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    AsyncPipe,
    CommonModule,
    RouterOutlet,
    BusyComponent, 
    TuiRoot,
    TuiDialog,
    TuiAlert,
    TuiPreview,
    TuiLet,
    TuiLineDaysChart,
    TuiLineDaysChartHint,
  ],
  standalone: true,
  providers: [
    httpInterceptorProviders,
  ]
})
export class AppComponent {
  protected readonly darkMode = inject(TUI_DARK_MODE);
  @Select(ThemeState.theme) theme$: Observable<'dark' | 'light' | null>;
  title = 'dataviewer';

  constructor() {}
}
