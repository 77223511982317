import { Inject, Injectable } from "@angular/core";
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { append, insertItem, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { GroupItem, ListItem } from "src/app/core/models/list-item";
import { OGC } from "src/app/core/models/ogc";
import { ZIndexService } from "src/app/ogc/services/zindex.service";
import { SketchGroup } from "./sketch.actions";
import { Ptor } from "protractor";

export interface SketchStateModel {
    loadedGroups: GroupItem<OGC>[];
    focusedLayer: ListItem<OGC>;
    loadingLayers: ListItem<OGC>[];
    unloadingLayers: ListItem<OGC>[];
    unloadingGroups: GroupItem<OGC>[];
}

export const SKETCH_STATE_MODEL_TOKEN = new StateToken<SketchStateModel>('Sketch');

@State<SketchStateModel>({
    name: SKETCH_STATE_MODEL_TOKEN,
    defaults: {
        loadedGroups: [],
        focusedLayer: null,
        loadingLayers: [],
        unloadingLayers: [],
        unloadingGroups: []
    }
})
@Injectable()
export class SketchState implements NgxsOnInit {
    private SKETCH_INITIAL_ZINDEX: number = 2000;

    @Selector([SKETCH_STATE_MODEL_TOKEN])
    static loadedGroups(state: SketchStateModel): GroupItem<OGC>[] {
        return state.loadedGroups;
    }

    @Selector([SKETCH_STATE_MODEL_TOKEN])
    static focusedLayer(state: SketchStateModel): ListItem<OGC> {
        return state.focusedLayer;
    }

    @Selector([SKETCH_STATE_MODEL_TOKEN])
    static unloadingGroups(state: SketchStateModel): GroupItem<OGC>[] {
        return state.unloadingGroups;
    }

    @Selector([SKETCH_STATE_MODEL_TOKEN])
    static loadingLayers(state: SketchStateModel): ListItem<OGC>[] {
        return state.loadingLayers;
    }

    @Selector([SKETCH_STATE_MODEL_TOKEN])
    static unloadingLayers(state: SketchStateModel): ListItem<OGC>[] {
        return state.unloadingLayers;
    }

    constructor(@Inject(ZIndexService) private zIndexService: ZIndexService) {}

    ngxsOnInit(ctx: StateContext<any>): void {
        console.log('State initialized, now setting all loaded items to unloaded in case some data is loaded from store');

        const state: SketchStateModel = ctx.getState();
        ctx.setState(
            patch<SketchStateModel>({
                loadedGroups: state.loadedGroups.map(group => {
                    const updatedGroup = {
                        ...group, loaded: 'unloaded', expanded: false, children: group.children.map(
                            child => {
                                const updatedChild  = { ...child, loaded: 'unloaded', show: false };
                                return updatedChild;
                            }
                        )
                    };
                    return updatedGroup as GroupItem<OGC>;
                }),
                unloadingGroups: [],
                loadingLayers: [],
                unloadingLayers: [],
                focusedLayer: null
            })
        )
    }

    @Action(SketchGroup.Load)
    loadGroup(ctx: StateContext<SketchStateModel>, {payload}: SketchGroup.Load) {
        const { loadedGroups } = ctx.getState();
        let indexedPayload: GroupItem<OGC>[];
        let insertIndex: number;
        if (payload.position === 'start') {
            indexedPayload = this.zIndexService.recalculateGroupIndex([payload.group, ...loadedGroups], this.SKETCH_INITIAL_ZINDEX);
            insertIndex = 0;
        } else {
            indexedPayload = this.zIndexService.recalculateGroupIndex([...loadedGroups, payload.group], this.SKETCH_INITIAL_ZINDEX);
            insertIndex = indexedPayload.length - 1;
        }
        const addedItem = indexedPayload[insertIndex];
        ctx.setState(
            patch({
                loadedGroups: insertItem(addedItem, insertIndex)
            })
        );
    }

    @Action(SketchGroup.Unload)
    unloadGroup(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Unload) {
        ctx.setState(
            patch({
                loadedGroups: removeItem<GroupItem<any, OGC>>(ogc => ogc.id === payload.id)
            })
        );
    }

    @Action(SketchGroup.Expand)
    expandGroup(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Expand) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.id === payload.id, patch({ expanded: true }))
            })
        );
    }

    @Action(SketchGroup.Close)
    closeGroup(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Close) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.id === payload.id, patch({ expanded: false }))
            })
        );
    }

    @Action(SketchGroup.Set)
    setGroups(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Set) {
        const indexedPayload = this.zIndexService.recalculateGroupIndex(payload, this.SKETCH_INITIAL_ZINDEX);
        const state = ctx.getState();
        ctx.setState({
            ...state,
            loadedGroups: indexedPayload
        });
    }

    @Action(SketchGroup.Show)
    showGroup(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Show) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.item.id, patch({ ...payload, show: true }))
            })
        );
    }

    @Action(SketchGroup.Hide)
    hideGroup(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Hide) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.item.id, patch({ ...payload, show: false }))
            })
        );
    }

    @Action(SketchGroup.SetLoadStatus)
    setGroupLoadStatus(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.SetLoadStatus) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.group.item.id, patch({ loaded: payload.status }))
            })
        );
    }

    @Action(SketchGroup.Layer.Set)
    setGroupChildren(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Layer.Set) {
        const indexedPayload = this.zIndexService.recalculateItemIndex(payload.children, payload.group.zIndex);

        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.group.item.id, patch({
                    children: indexedPayload
                }))
            })
        );
    }

    @Action(SketchGroup.Layer.Show)
    showGroupChild(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Layer.Show) {
        const { group, layer } = payload;
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === group.item.id,
                    patch({
                        children: updateItem<ListItem<OGC>>(child => 
                            child.item.id === layer.item.id,
                            patch({ show: true })
                        )
                    }),
                ),
            }),
        );
    }

    @Action(SketchGroup.Layer.Hide)
    hideGroupChild(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Layer.Hide) {
        const { group, layer } = payload;
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === group.item.id,
                    patch({
                        children: updateItem<ListItem<OGC>>(child => 
                            child.item.id === layer.item.id,
                            patch({ show: false })
                        )
                    }),
                ),
            }),
        );
    }

    @Action(SketchGroup.Layer.Focus)
    focusGroupChild(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Layer.Focus) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            focusedLayer: payload ? { ...payload } : null// create a copy to always fire the event
        });
    }

    @Action(SketchGroup.Layer.Append)
    appendGroupLayer(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Layer.Append) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.group.id,
                    patch({
                        children: append(payload.children)
                    }),
                ),
            }),
        );
    }

    @Action(SketchGroup.Layer.AppendLoading)
    appendLoadingItems(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Layer.AppendLoading) {
        const { layers } = payload;
        ctx.setState(
            patch({
                loadingLayers: append(layers)
            })
        );
    }

    @Action(SketchGroup.Layer.RemoveLoading)
    removeLoadingItems(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.Layer.RemoveLoading) {
        const { layers } = payload;
        const state = ctx.getState();
        ctx.setState({
            ...state,
            loadingLayers: state.loadingLayers.filter(item => ! layers.find(layer => item === layer))
        })
    }

    @Action(SketchGroup.AppendUnloading)
    appendUnloadingItems(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.AppendUnloading) {
        const { groups } = payload;
        ctx.setState(
            patch({
                loadedGroups: append(groups)
            })
        );
    }

    @Action(SketchGroup.RemoveUnloading)
    removeUnloadingItems(ctx: StateContext<SketchStateModel>, { payload }: SketchGroup.RemoveUnloading) {
        const { groups } = payload;
        const state = ctx.getState();
        ctx.setState({
            ...state,
            loadedGroups: state.loadedGroups.filter(item => ! groups.find(group => item === group))
        })
    }
}
