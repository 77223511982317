import { Inject, Injectable } from "@angular/core";
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { patch, removeItem, updateItem, insertItem, append } from '@ngxs/store/operators';
import { AppendableListItem, GroupItem, ListItem, ProjectItem } from "src/app/core/models/list-item";
import { OGC } from "src/app/core/models/ogc";
import { ZIndexService } from "src/app/ogc/services/zindex.service";
import { Project } from "./project.actions";

interface ProjectStateModel {
    loadedProjects: ProjectItem<OGC>[];
    unloadingProjects: ProjectItem<OGC>[];
    loadingLayers: AppendableListItem<OGC>[];
    unloadingLayers: ListItem<OGC>[];
    focusedLayer: ListItem<OGC>;
}

export const PROJECT_STATE_MODEL_TOKEN = new StateToken<ProjectStateModel>('Project');

@State<ProjectStateModel>({
    name: PROJECT_STATE_MODEL_TOKEN,
    defaults: {
        loadedProjects: [],
        unloadingProjects: [],
        loadingLayers: [],
        unloadingLayers: [],
        focusedLayer: null
    }
})
@Injectable()
export class ProjectState implements NgxsOnInit {
    private OGC_INITIAL_ZINDEX: number = 30000;

    ngxsOnInit(ctx: StateContext<any>): void {
        console.log('State initialized, now setting all loaded items to unloaded in case some data is loaded from store');

        const state: ProjectStateModel = ctx.getState();
        ctx.setState(
            patch<ProjectStateModel>({
                loadedProjects: state.loadedProjects.map(project => {
                    const updatedProject = {
                        ...project, loaded: 'unloaded', expanded: false, children: project.children.map(
                            group => {
                                const updatedGroup  = { ...group, loaded: 'unloaded' };
                                return {...updatedGroup, expanded: false, children: updatedGroup.children.map(
                                    child => {
                                        const updatedChild = { ...child, loaded: 'unloaded', show: false };
                                        return updatedChild;
                                    }
                                )};
                            }
                        )
                    };
                    return updatedProject as ProjectItem<OGC>;
                }),
                unloadingProjects: [],
                loadingLayers: [],
                unloadingLayers: [],
                focusedLayer: null
            })
        )
    }

    static findProject(id: string) {
        return createSelector([ProjectState], (state: ProjectStateModel) => {
            return state.loadedProjects.find(project => project.id === id);
        });
    }

    static findGroup(id: string) {
        return createSelector([ProjectState], (state: ProjectStateModel) => {
            let foundGroup: GroupItem<OGC> = null;
            state.loadedProjects.some(project => {
                return project.children.some(group => {
                    foundGroup = group.id === id ? group : null;
                    return group.id === id;
                });
            }, null);

            return foundGroup;
        });

    }


    @Selector([PROJECT_STATE_MODEL_TOKEN])
    static loadedProjects(state: ProjectStateModel): ProjectItem<OGC>[] {
        return state.loadedProjects;
    }

    @Selector([PROJECT_STATE_MODEL_TOKEN])
    static unloadingProjects(state: ProjectStateModel): ProjectItem<OGC>[] {
        return state.unloadingProjects;
    }

    @Selector([PROJECT_STATE_MODEL_TOKEN])
    static focusedLayer(state: ProjectStateModel): ListItem<OGC> {
        return state.focusedLayer;
    }

    @Selector([PROJECT_STATE_MODEL_TOKEN])
    static loadingLayers(state: ProjectStateModel): AppendableListItem<OGC>[] {
        return state.loadingLayers;
    }

    @Selector([PROJECT_STATE_MODEL_TOKEN])
    static unloadingLayers(state: ProjectStateModel): ListItem<OGC>[] {
        return state.unloadingLayers;
    }

    constructor(@Inject(ZIndexService) private zIndexService: ZIndexService) {}

    @Action(Project.Load)
    loadProject(ctx: StateContext<ProjectStateModel>, {payload}: Project.Load) {
        const { loadedProjects } = ctx.getState();
        const indexedPayload = this.zIndexService.recalculateProjectIndex([payload, ...loadedProjects], this.OGC_INITIAL_ZINDEX);
        const addedItem = indexedPayload[0];
        ctx.setState(
            patch({
                loadedProjects: insertItem(addedItem, 0)
            })
        );
    }

    @Action(Project.Modify)
    modifyProject(ctx: StateContext<ProjectStateModel>, {payload}: Project.Modify) {
        const { projectId, project } = payload;
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(proj => proj.id === projectId,
                    project as any
                )
            })
        );
    }

    @Action(Project.Unload)
    unloadProject(ctx: StateContext<ProjectStateModel>, { payload }: Project.Unload) {
        ctx.setState(
            patch({
                loadedProjects: removeItem<ProjectItem<OGC>>(project => project.id === payload.id)
            })
        );
    }

    @Action(Project.Expand)
    expandProject(ctx: StateContext<ProjectStateModel>, { payload }: Project.Expand) {
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(plantation => plantation.id === payload.id, patch({ expanded: true }))
            })
        );
    }

    @Action(Project.Close)
    closeProject(ctx: StateContext<ProjectStateModel>, { payload }: Project.Close) {
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(plantation => plantation.id === payload.id, patch({ expanded: false }))
            })
        );
    }
    
    @Action(Project.Show)
    showProject(ctx: StateContext<ProjectStateModel>, { payload }: Project.Show) {
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(plantation => plantation.id === payload.id, patch({ show: true }))
            })
        );
    }

    @Action(Project.Hide)
    hideProject(ctx: StateContext<ProjectStateModel>, { payload }: Project.Hide) {
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(plantation => plantation.id === payload.id, patch({ show: false }))
            })
        );
    }

    @Action(Project.Set)
    setProjects(ctx: StateContext<ProjectStateModel>, { payload }: Project.Set) {
        const indexedPayload = this.zIndexService.recalculateProjectIndex(payload, this.OGC_INITIAL_ZINDEX);

        const state = ctx.getState();
        ctx.setState({
            ...state,
            loadedProjects: indexedPayload
        });
    }

    @Action(Project.Group.Load)
    loadGroup(ctx: StateContext<ProjectStateModel>, {payload}: Project.Group.Load) {
        const state = ctx.getState();
        const project = state.loadedProjects.find(project => project.id === payload.parentId);
        const groups = project
            ? this.zIndexService.recalculateGroupIndex([payload, ...project.children], project.zIndex)
             : [];
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(group => group.id === payload.id),
                    patch({ children: groups })
                )
            })
        )
    }

    @Action(Project.Group.Unload)
    unloadGroup(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Unload) {
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(pGroup => pGroup.id === payload.id),
                    patch({ children: removeItem<GroupItem<OGC>>(
                        group => group.id === group.id
                    )})
                )
            })
        );
    }

    @Action(Project.Group.Expand)
    expandGroup(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Expand) {
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(group => group.id === payload.id),
                    patch({
                        children: updateItem<GroupItem<OGC>>(
                            loadedGroup => loadedGroup.id === payload.id,
                            patch({ expanded: true })
                        )
                    })
                )
            })
        );
    }

    @Action(Project.Group.Close)
    closeGroup(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Close) {
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(group => group.id === payload.id),
                    patch({
                        children: updateItem<GroupItem<OGC>>(
                            loadedGroup => loadedGroup.id === payload.id,
                            patch({ expanded: false })
                        )
                    })
                )
            })
        );
    }

    @Action(Project.Group.Set)
    setGroups(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Set) {
        const state = ctx.getState();
        const project = payload.length 
            ? state.loadedProjects.find(project => project.id === payload[0].parentId)
            : null;
        const indexedGroups = project 
            ? this.zIndexService.recalculateGroupIndex(payload, project.zIndex)
            : [];
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    loadedProject => loadedProject.id === project.id,
                    patch({ children: indexedGroups })
                )
            })
        );
    }

    @Action(Project.Group.SetLoadStatus)
    setGroupLoadStatus(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.SetLoadStatus) {
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(group => group.id === payload.group.id),
                    patch({
                        children: updateItem<GroupItem<OGC>>(
                            loadedGroup => loadedGroup.id === payload.group.id,
                            patch({ loaded: payload.status })
                        )
                    })
                )
            })
        );
    }

    @Action(Project.Group.Layer.Set)
    setGroupChildren(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Layer.Set) {
        const { group, children } = payload;
        const indexedChildren = this.zIndexService.recalculateItemIndex(children, group.zIndex);
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(loadedGroup => loadedGroup.id === group.id),
                    patch({
                        children: updateItem<GroupItem<OGC>>(
                            loadedGroup => loadedGroup.id === group.id,
                            patch({ children: indexedChildren })
                        )
                    })
                )
            })
        );
    }

    @Action(Project.Group.Show)
    showGroup(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Show) {
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(group => group.id === payload.id),
                    patch({
                        children: updateItem<GroupItem<OGC>>(
                            loadedGroup => loadedGroup.id === payload.id,
                            patch({ show: true })
                        )
                    })
                )
            })
        );
    }

    @Action(Project.Group.Hide)
    hideGroup(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Hide) {
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(group => group.id === payload.id),
                    patch({
                        children: updateItem<GroupItem<OGC>>(
                            loadedGroup => loadedGroup.id === payload.id,
                            patch({ show: false })
                        )
                    })
                )
            })
        );
    }

    @Action(Project.Group.Layer.Show)
    showGroupChild(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Layer.Show) {
        const { group, layer } = payload;
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(loadedGroup => loadedGroup.id === group.id),
                    patch({
                        children: updateItem<GroupItem<OGC>>(
                            loadedGroup => loadedGroup.id === group.id,
                            patch({ children: updateItem<ListItem<OGC>>(
                                listItem => listItem.id === layer.id,
                                patch({ show: true })
                            ) })
                        )
                    })
                )
            })
        );
    }

    @Action(Project.Group.Layer.Hide)
    hideGroupChild(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Layer.Hide) {
        const { group, layer } = payload;
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(loadedGroup => loadedGroup.id === group.id),
                    patch({
                        children: updateItem<GroupItem<OGC>>(
                            loadedGroup => loadedGroup.id === group.id,
                            patch({ children: updateItem<ListItem<OGC>>(
                                listItem => listItem.id === layer.id,
                                patch({ show: false })
                            ) })
                        )
                    })
                )
            })
        );
    }

    @Action(Project.Group.Layer.SetLoadStatus)
    setLoadStatusGroupChild(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Layer.SetLoadStatus) {
        const { layer, status } = payload;
        ctx.setState(
            patch({
                loadedProjects: updateItem<ProjectItem<OGC>>(
                    project => !! project.children.find(loadedGroup => loadedGroup.id === layer.parentId),
                    patch({
                        children: updateItem<GroupItem<OGC>>(
                            loadedGroup => loadedGroup.id === layer.parentId,
                            patch({ children: updateItem<ListItem<OGC>>(
                                listItem => listItem.id === layer.id,
                                patch({ loaded: status })
                            ) })
                        )
                    })
                )
            })
        );
    }

    // @Action(Project.Group.Layer.SetStyles)
    // setLayerColor(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Layer.SetStyles) {
    //     const { group, layer, styles } = payload;
    //     ctx.setState(
    //         patch({
    //             loadedProjects: updateItem<ProjectItem<OGC>>(
    //                 project => !! project.children.find(loadedGroup => loadedGroup.id === group.id),
    //                 patch({
    //                     children: updateItem<GroupItem<OGC>>(
    //                         loadedGroup => loadedGroup.id === group.id,
    //                         patch({ children: updateItem<ListItem<OGC>>(
    //                             listItem => listItem.id === layer.id,
    //                             patch({ styles: styles })
    //                         ) })
    //                     )
    //                 })
    //             )
    //         })
    //     );
    // }

    @Action(Project.AppendUnloading)
    appendUnloadingGroups(ctx: StateContext<ProjectStateModel>, { payload }: Project.AppendUnloading) {
        const { layers } = payload;
        ctx.setState(
            patch({
                unloadingProjects: append(layers)
            })
        );
    }

    @Action(Project.RemoveUnloading)
    removeUnloadingGroups(ctx: StateContext<ProjectStateModel>, { payload }: Project.RemoveUnloading) {
        const { layers } = payload;
        const state = ctx.getState();
        ctx.setState({
            ...state,
            unloadingProjects: state.unloadingProjects.filter(item => ! layers.find(layer => item === layer))
        })
    }

    @Action(Project.Group.Layer.AppendLoading)
    appendLoadingItems(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Layer.AppendLoading) {
        const { layers } = payload;
        ctx.setState(
            patch({
                loadingLayers: append(layers)
            })
        );
    }

    @Action(Project.Group.Layer.RemoveLoading)
    removeLoadingItems(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Layer.RemoveLoading) {
        const { layers } = payload;
        const state = ctx.getState();
        ctx.setState({
            ...state,
            loadingLayers: state.loadingLayers.filter(item => ! layers.find(layer => item === layer))
        })
    }

    @Action(Project.Group.Layer.AppendUnloading)
    appendUnloadingItems(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Layer.RemoveUnloading) {
        const { layers } = payload;
        ctx.setState(
            patch({
                unloadingLayers: append(layers)
            })
        );
    }

    @Action(Project.Group.Layer.RemoveUnloading)
    removeUnloadingItems(ctx: StateContext<ProjectStateModel>, { payload }: Project.Group.Layer.RemoveUnloading) {
        const { layers } = payload;
        const state = ctx.getState();
        ctx.setState({
            ...state,
            loadingLayers: state.loadingLayers.filter(item => ! layers.find(layer => item === layer))
        })
    }
}
