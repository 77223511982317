import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken, createSelector } from "@ngxs/store";
import { append, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { FeatureFlatStyle, LayerStyle } from "src/app/core/models/feature-style";
import { Styles } from "./sketch-style.actions";

interface FeatureStyleStateModel {
  layers: LayerStyle[];
}

export const SKETCH_STYLE_TOKEN = new StateToken<SketchStyleState>('SketchStyle');

@State<FeatureStyleStateModel>({
  name: SKETCH_STYLE_TOKEN,
  defaults: {
    layers: []
  }
})
@Injectable()
export class SketchStyleState {
  @Selector()
  static layers(state: FeatureStyleStateModel): LayerStyle[] {
    return state.layers;
  }

  @Selector()
  static layersGroupedById(state: FeatureStyleStateModel): Record<string, FeatureFlatStyle> {
    const layersMap: Record<string, FeatureFlatStyle> = {};
    state.layers.forEach(layer => {
      layersMap[layer.layerId] = layer.style.flatStyle;
    });
    return layersMap;
  }

  static style(id: string | number) {
    return createSelector([SketchStyleState], (state: FeatureStyleStateModel) => {
      const foundLayer = state.layers.find(item => item.layerId === id);
      return foundLayer ? foundLayer.style : null;
    });
  }

  static flatStyle(id: string | number) {
    return createSelector([SketchStyleState], (state: FeatureStyleStateModel) => {
      const foundLayer = state.layers.find(item => item.layerId === id);
      return foundLayer ? foundLayer.style.flatStyle : null;
    });
  }

  @Action(Styles.Add)
  addStyle(ctx: StateContext<FeatureStyleStateModel>, {payload}: Styles.Add) {
    const state = ctx.getState();
    const layer = state.layers.find(item => item.layerId === payload.layerId);

    if (! layer) { // Create the new layer item
      const layerStyles: LayerStyle = {
        layerId: payload.layerId,
        style: payload.style
      };

      ctx.setState(
        patch({
          layers: append([layerStyles])
        })
      )
    } else { // update existing layer item
      ctx.setState(
        patch({
            layers: updateItem<LayerStyle>(layerItem => layerItem.layerId === payload.layerId,
              patch({
                style: payload.style
              })
            )
        })
      );
    }
  }

  @Action(Styles.Remove)
  removeStyle(ctx: StateContext<FeatureStyleStateModel>, {payload}: Styles.Remove) {
      ctx.setState(
          patch({
            layers: removeItem<LayerStyle>(layerItem => layerItem.layerId === payload.layerId)
          })
      );
  }

  @Action(Styles.Modify)
  modifyStyle(ctx: StateContext<FeatureStyleStateModel>, { payload }: Styles.Modify) {
    ctx.setState(
      patch({
        layers: updateItem<LayerStyle>(layerItem => layerItem.layerId === payload.layerId,
          patch({
            style: payload.style
          })
        )
      })
    );
  }

  @Action(Styles.Set)
  setStyle(ctx: StateContext<FeatureStyleStateModel>, { payload }: Styles.Set) {
    ctx.setState(payload);
  }
}