import { GroupItem, ListItem, LoadStatus } from "src/app/core/models/list-item";
import { OGC as IOGC } from "src/app/core/models/ogc";

export namespace SketchGroup {
    export class Load {
        static readonly type = '[Sketch] Load Group';
        constructor(public payload: {group: GroupItem<IOGC>, position: 'start' | 'end'}) {}
    }
    
    export class Unload {
        static readonly type = '[Sketch] Unload Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }
    
    export class Expand {
        static readonly type = '[Sketch] Expand Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }
    
    export class Close {
        static readonly type = '[Sketch] Close Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }
    
    export class Set {
        static readonly type = '[Sketch] Set Groups';
        constructor(public payload: GroupItem<IOGC>[]) {}
    }

    export class SetLoadStatus {
        static readonly type = '[Sketch] Set Load Status';
        constructor(public payload: {group: GroupItem<IOGC>, status: LoadStatus}) {}
    }
    
    export class Show {
        static readonly type = '[Sketch] Show Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }
    
    export class Hide {
        static readonly type = '[Sketch] Hide Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }

    export class AppendUnloading {
        static readonly type = '[Sketch] Append Group Unloading';
        constructor(public payload: { groups: GroupItem<IOGC>[] }) {}
        
    }

    export class RemoveUnloading {
        static readonly type = '[Sketch] Remove Group Unloading';
        constructor(public payload: { groups: GroupItem<IOGC>[] }) {}
    }

    export namespace Layer {
        export class Set {
            static readonly type = '[Sketch] Set Group layers';
            constructor(public payload: { group: GroupItem<IOGC>, children: ListItem<IOGC>[]}) {}
        }
        
        export class Append {
            static readonly type = '[Sketch] Append Group layers';
            constructor(public payload: { group: GroupItem<IOGC>, children: ListItem<IOGC>[]}) {}
        }
        
        export class Show {
            static readonly type = '[Sketch] Show Group layer';
            constructor(public payload: { group: GroupItem<IOGC>, layer: ListItem<IOGC> }) {}
        }
        
        export class Hide {
            static readonly type = '[Sketch] Hide Group layer';
            constructor(public payload: { group: GroupItem<IOGC>, layer: ListItem<IOGC> }) {}
        }
        
        export class Focus {
            static readonly type = '[Sketch] Focus Group layer';
            constructor(public payload: ListItem<IOGC>) {}
        }

        export class AppendLoading {
            static readonly type = '[Sketch] Append Layer Loading';
            constructor(public payload: { layers: ListItem<IOGC>[] }) {}
            
        }
    
        export class RemoveLoading {
            static readonly type = '[Sketch] Remove Layer Loading';
            constructor(public payload: { layers: ListItem<IOGC>[] }) {}
        }
    }
}

