import { nanoid } from "nanoid";
import { FeatureFlatStyle, FeatureStyle, FeatureWebGLStyle } from "../models/feature-style";

export const ACTIVE_STYLE: FeatureStyle = {
  id: nanoid(),
  fill: {
    color: '#4ed455',
    pattern: 'solid',
    patternSrc: null
  },
  stroke: {
    color: 'rgba(200, 89, 192, 0.7)',
    width: 2,
    pattern: 'solid',
    patternSrc: null
  },
  radius: 7
};

export const HOVER_STYLE: FeatureStyle = {
  id: nanoid(),
    fill: {
      color: '#eeeeee',
      pattern: 'solid',
      patternSrc: null
    },
    stroke: {
      color: 'rgba(255, 255, 255, 0.7)',
      width: 2,
      pattern: 'solid',
      patternSrc: null
    },
    radius: 7
  };

export const STYLE: FeatureStyle = {
  id: nanoid(),
  stroke: { 
    color: 'rgba(51, 153, 204, 1)',
    width: 1,
    pattern: 'solid',
    patternSrc: null
  }, 
  fill: {
    color: 'rgba(255,255,255,0.4)',
    pattern: 'solid',
    patternSrc: null
  },
  radius: 5
}

export const SKETCH_STYLE: FeatureStyle = {
  id: nanoid(),
  stroke: {
    color: '#ffcc33',
    width: 2,
    pattern: 'solid',
    patternSrc: null
  },
  fill: {
    color: 'rgba(255, 255, 255, 0.2)',
    pattern: 'solid',
    patternSrc: null
  },
  radius: 7
}

export const FILTER_STYLE: FeatureStyle = {
  id: nanoid(),
  stroke: { 
    color: 'black',
    width: 1,
    // pattern: 'solid',
    // patternSrc: null
  }, 
  fill: {
    color: 'red',
    // pattern: 'solid',
    // patternSrc: null
  },
  radius: 5
}

export const FLAT_ACTIVE_STYLE: FeatureFlatStyle = {
  id: nanoid(),
  // Styles for polygons and lines
  "stroke-color": 'rgba(200, 89, 192, 0.7)',
  "stroke-width": 2,
  // "stroke-line-dash": [0, 0, 0, 0],
  // "stroke-line-cap": 'butt', 
  "fill-color": '#4ed455',
  // "fill-pattern-src": null,
  // Styles for circles and points
  "circle-radius": 7,
  "circle-stroke-color": 'rgba(200, 89, 192, 0.7)',
  "circle-stroke-width": 2,
  "circle-fill-color": '#4ed455',
}

export const FLAT_HOVER_STYLE: FeatureFlatStyle = {
  id: nanoid(),
  // Styles for polygons and lines
  "stroke-color": 'rgba(255, 255, 255, 0.7)',
  "stroke-width": 2,
  // "stroke-line-dash": [0, 0, 0, 0],
  // "stroke-line-cap": 'butt', 
  "fill-color": '#eeeeee',
  // "fill-pattern-src": null,
  // Styles for circles and points
  "circle-radius": 7,
  "circle-stroke-color": 'rgba(255, 255, 255, 0.7)',
  "circle-stroke-width": 2,
  "circle-fill-color": '#eeeeee',
}

export const FLAT_STYLE: FeatureFlatStyle = {
  id: nanoid(),
  // Styles for polygons and lines
  "stroke-color": 'rgba(51, 153, 204, 1)',
  "stroke-width": 1,
  "stroke-line-dash": [0, 0, 0, 0],
  "stroke-line-cap": 'butt', 
  "fill-color": 'rgba(255,255,255,0.4)',
  // "fill-pattern-src": null,
  // Styles for circles and points
  "circle-radius": 5,
  "circle-stroke-color": 'rgba(51, 153, 204, 1)',
  "circle-stroke-width": 1,
  "circle-fill-color": 'rgba(255,255,255,0.4)',
}

export const SKETCH_FLAT_STYLE: FeatureFlatStyle = {
  id: nanoid(),
"fill-color": 'rgba(255, 255, 255, 0.2)',
  "stroke-width": 2,
  "stroke-color": '#ffcc33',
  // Styles for polygons and lines
  // Styles for circles and points
  "circle-radius": 7,
  "circle-stroke-color": '#ffcc33',
  "circle-stroke-width": 2,
  "circle-fill-color": '#ffcc33'
}

export const FILTER_FLAT_STYLE: FeatureFlatStyle = {
  id: nanoid(),
  // Styles for polygons and lines
  "stroke-color": 'black',
  "stroke-width": 1,
  "fill-color": 'red',
  // "stroke-line-dash": [0, 0, 0, 0],
  // "stroke-line-cap": 'butt', 
  // "fill-pattern-src": null,
  // Styles for circles and points
  "circle-radius": 5,
  "circle-stroke-color": 'black',
  "circle-stroke-width": 1,
  "circle-fill-color": 'red',
}

export const WEBGL_STYLE: FeatureWebGLStyle = {
  id: nanoid(),
  // Styles for polygons and lines
  "stroke-color": ['color', ['var', 'strokeRed'], ['var', 'strokeGreen'], ['var', 'strokeBlue'], ['var', 'strokeAlpha']],
  "stroke-width": ['var', 'width'],
  "fill-color": ['color', ['var', 'fillRed'], ['var', 'fillGreen'], ['var', 'fillBlue'], ['var', 'fillAlpha']],
  // Styles for circles and points
  "circle-radius": ['var', 'radius'],
  "circle-stroke-color": ['color', ['var', 'strokeRed'], ['var', 'strokeGreen'], ['var', 'strokeBlue'], ['var', 'strokeAlpha']],
  "circle-stroke-width": ['var', 'width'],
  "circle-fill-color": ['color', ['var', 'fillRed'], ['var', 'fillGreen'], ['var', 'fillBlue'], ['var', 'fillAlpha']],
  variables: {
    width: 1,
    radius: 5,
    strokeRed: 51,
    strokeGreen: 153,
    strokeBlue: 204,
    strokeAlpha: 1,
    fillRed: 255,
    fillGreen: 255,
    fillBlue: 255,
    fillAlpha: 0.4
  }
}

export const WEBGL_ACTIVE_STYLE: FeatureWebGLStyle = {
  ...WEBGL_STYLE,
  id: nanoid(),
  variables: {
    width: 2,
    radius: 7,
    strokeRed: 200,
    strokeGreen: 89,
    strokeBlue: 192,
    strokeAlpha: 0.7,
    fillRed: 78,
    fillGreen: 212,
    fillBlue: 85,
    fillAlpha: 1
  }
}

export const WEBGL_HOVER_STYLE: FeatureWebGLStyle = {
  ...WEBGL_STYLE,
  id: nanoid(),
  variables: {
    width: 2,
    radius: 7,
    strokeRed: 200,
    strokeGreen: 89,
    strokeBlue: 192,
    strokeAlpha: 0.7,
    fillRed: 238,
    fillGreen: 238,
    fillBlue: 238,
    fillAlpha: 1
  }
}

export const FILTER_WEBGL_STYLE: FeatureWebGLStyle = {
  ...WEBGL_STYLE,
  id: nanoid(),
  variables: {
    width: 1,
    radius: 5,
    strokeRed: 0,
    strokeGreen: 0,
    strokeBlue: 0,
    strokeAlpha: 1,
    fillRed: 255,
    fillGreen: 0,
    fillBlue: 0,
    fillAlpha: 1
  }
}