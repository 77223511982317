import { importProvidersFrom } from '@angular/core';
import { Route } from '@angular/router';

import { NotFoundComponent } from './core/components/not-found.component';
// import { AgromonitoringComponent } from './agromonitoring/agromonitoring.component';
import { AuthGuard } from './core/guards/auth.guard';
import { NgxsModule } from '@ngxs/store';
import { ViewerMapState } from './pages/viewer-page/state/viewer-map.state';
import { LayerState } from './layer-toggle/state/layer.state';
import { SidebarState } from './pages/viewer-page/state/sidebar.state';
import { OGCState } from './ogc/state/ogc.state';
import { ProjectState } from './plantation/state/project.state';
import { OgcFeatureFilterState } from './feature-filter/state/ogc-feature-filter.state';
import { OgcFeatureStyleState } from './feature-style/state/ogc-feature-style.state';
import { SketchState } from './sketch/state/sketch.state';
import { FeatureModifyState } from './pages/viewer-page/state/feature-modify.state';
import { SketchStyleState } from './sketch/state/sketch-style.state';

export const APP_ROUTES: Route[] = [
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin-page/routes').then(m => m.ADMIN_PAGE_ROUTES),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'viewer',
    loadChildren: () => import('./pages/viewer-page/routes').then(m => m.VIEWER_PAGE_ROUTES),
    providers: [
      importProvidersFrom(
        NgxsModule.forFeature([
          ViewerMapState,
          LayerState,
          SidebarState,
          OGCState,
          ProjectState,
          OgcFeatureStyleState,
          OgcFeatureFilterState,
          SketchState,
          SketchStyleState,
          FeatureModifyState
        ])
      )
    ]
    // canActivate: [ AuthGuard ]
  },
  {
    path: '',
    // pathMatch: 'full',
    loadChildren: () => import('./pages/auth-page/routes').then(m => m.AUTH_PAGE_ROUTES)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];
