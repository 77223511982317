import { GeoJSONGeometry } from "ol/format/GeoJSON";
import { FeatureModify } from "src/app/core/models/modify";

export namespace Modify {
  export class Add {
    static readonly type = '[FeatureModify] Add Layer';
    constructor(public payload: {layerId: string | number, feature: FeatureModify}) {}
  }
  
  export class Remove {
    static readonly type = '[FeatureModify] Remove Layer';
    constructor(public payload: {layerId: string | number}) {}
  }
  
  export class Modify {
    static readonly type = '[FeatureModify] Modify Layer';
    constructor(public payload:  {layerId: string | number, feature: FeatureModify}) {}
  }

  export class AddGeometry {
    static readonly type = '[FeatureModify] Add Feature';
    constructor(public payload:  {layerId: string | number, feature: FeatureModify}) {}
  }

  export class RemoveGeometry {
    static readonly type = '[FeatureModify] Remove Feature';
    constructor(public payload:  {layerId: string | number, featureId: string}) {}
  }

  export class UpdateGeometry {
    static readonly type = '[FeatureModify] Update Feature';
    constructor(public payload:  {layerId: string | number, feature: GeoJSONGeometry}) {}
  }

  // export class UpdateGeometries {
  //   static readonly type = '[FeatureModify] Update Features';
  //   constructor(public payload:  {layerId: string | number, features: GeoJSONGeometry[]}) {}
  // }

  export class Set {
    static readonly type = '[FeatureModify] Set Layer';
    constructor(public payload: { layers: FeatureModify[] }) {}
  }

  export class SetActiveLayer {
    static readonly type = '[FeatureModify] Set Active Layer';
    constructor(public payload: { layer: string | number }) {}
  }

  export class SetDrawingGeometry {
    static readonly type = '[FeatureModify] Set Drawing Feature';
    constructor(public payload: GeoJSONGeometry) {}
  }
}
