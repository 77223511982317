import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface BusyPayload {
    isBusy: boolean;
    message?: string;
}

@Injectable({ providedIn: 'root' })
export class BusyService {
    private notBusyPayload: BusyPayload = { isBusy: false, message: '' };
    private subject = new ReplaySubject<BusyPayload>();
    private busyCounter = 0;
    busyState$ = this.subject.asObservable();

    addRequest(message: string): void {
        this.busyCounter++;
        const payload: BusyPayload = { isBusy: true, message };
        this.subject.next(payload);
    }

    removeRequest(): void {
        this.busyCounter--;
        if (this.busyCounter <= 0) {
            this.subject.next(this.notBusyPayload);
        }
    }
}
