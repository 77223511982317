import { Injectable, NgModuleRef, OnDestroy, Type } from "@angular/core";
import { Action, Actions, Selector, State, StateContext, StateToken, Store, ofActionSuccessful } from "@ngxs/store";
import { SidebarRoute } from "src/app/core/models/sidebar-route";
import { sidebarFooterRoutes, sidebarRoutes } from "../repository/sidebar.repository";
import { CloseSidebar, OpenSidebar, ResizeSidebar, SetPath } from "./sidebar.actions";
import { RouterNavigation } from "@ngxs/router-plugin";
import { Subject, filter, mergeMap, of, switchMap, takeUntil } from "rxjs";


interface SidebarStateModel {
    activeRoute: SidebarRoute;
    routes: SidebarRoute[];
    footerRoutes: SidebarRoute[];
    open: boolean;
    size: 'l' | 'm' | 's';
}

const SIDEBAR_STATE_TOKEN = new StateToken<SidebarStateModel>('Sidebar');

@State({
    name: SIDEBAR_STATE_TOKEN,
    defaults: {
        activeRoute: null,
        routes: sidebarRoutes,
        footerRoutes: sidebarFooterRoutes,
        open: false,
        size: 's'
    }
})
@Injectable()
export class SidebarState implements OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(actions$: Actions, store: Store) {
        actions$.pipe(
            ofActionSuccessful(RouterNavigation),
            takeUntil(this.destroy$),
            mergeMap((action: RouterNavigation) => {
                let next$ = of([]);
                const pathWithoutQueryParams = action.routerState.url.split('?')[0];
                // Compare the current state route with the navigation one to avoid firing unnecessary SetPath actions
                // const currRoute = store.selectSnapshot<SidebarRoute>((state: any) => [...state.Sidebar.routes, ...state.Sidebar.footerRoutes].find(route => route.path === pathWithoutQueryParams));
                const currActiveRoute = store.selectSnapshot<SidebarRoute>((state: any) => state.Sidebar.activeRoute);
                if (pathWithoutQueryParams !== currActiveRoute?.path) {
                    next$ = store.dispatch(new SetPath(pathWithoutQueryParams));
                }

                // if (currRoute?.fullscreen) {
                //     next$ = next$.pipe(
                //         filter(() => currRoute.fullscreen),
                //         switchMap(() => store.dispatch(new ResizeSidebar('l')))
                //     );
                // }

                return next$;
            }),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    @Selector()
    static activeRoute(state: SidebarStateModel): SidebarRoute | null {
      return state.activeRoute;
    }

    @Selector()
    static routes(state: SidebarStateModel): SidebarRoute[] {
        return state.routes;
    }

    @Selector()
    static footerRoutes(state: SidebarStateModel): SidebarRoute[] {
        return state.footerRoutes;
    }

    @Selector()
    static open(state: SidebarStateModel): boolean | null {
      return state.open;
    }

    @Selector()
    static size(state: SidebarStateModel): 'l' | 'm' | 's' | null {
      return state.size;
    }

    // This state will be mostly handled by routing
    @Action(SetPath)
    setPath(ctx: StateContext<SidebarStateModel>, { payload }: SetPath) {
        const state = ctx.getState();
        const mergedRoutes = [...state.routes, ...state.footerRoutes];
        let activeRoute = mergedRoutes.find(route => payload.includes(route.path));

        // If a payload is set, create a temporal route
        if (payload && ! activeRoute) {
            activeRoute = { icon: null, title: payload.split('/').join(' '), path: payload, fullscreen: false };
        }

        ctx.setState({
            ...state,
            activeRoute
        });
    }

    @Action(OpenSidebar)
    openSidebar(ctx: StateContext<SidebarStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            open: true
        });
    }

    @Action(CloseSidebar)
    closeSidebar(ctx: StateContext<SidebarStateModel>) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            open: false
        });
    }

    @Action(ResizeSidebar)
    resizeSidebar(ctx: StateContext<SidebarStateModel>, { payload }: ResizeSidebar) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            size: payload
        });
    }
}