import { gql } from "apollo-angular";

export const CREATE_USER = gql`
  mutation CreateUser($userName: String!, $email: String!, $password: String!) {
    createUser(input: {name: $userName, email: $email, password: $password, active: true}) {
        attributeName
        deliveryMedium
        destination
    }
  }
`;

export const CONFIRM_USER = gql`
  mutation ConfirmUser($userName: String!, $code: String!) {
      confirmUser(userName: $userName, code: $code) {
          success
          message
      }
  }
`;