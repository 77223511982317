import { Component, OnInit } from '@angular/core';
import { observeOn } from 'rxjs/operators';
import { asapScheduler } from 'rxjs';
import { BusyService } from '../services/busy.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-busy',
    template: `
        <div>
            <p i18n *ngIf="busy" style="font-size: 5rem;">This is the code that is displayed while the page is busy</p>
        </div>
    `,
    standalone: true,
    imports: [NgIf]
})

export class BusyComponent implements OnInit {
    busy: boolean;

    constructor(private busyService: BusyService) { }

    ngOnInit(): void {
        this.busyService.busyState$.pipe(observeOn(asapScheduler))
        .subscribe(state => this.busy = state.isBusy);
    }
}
