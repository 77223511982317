import { Inject, Injectable } from "@angular/core";
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { ZIndexService } from "../services/zindex.service";
import { append, insertItem, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { GroupItem, ListItem } from "src/app/core/models/list-item";
import { OGC } from "src/app/core/models/ogc";
import { Group } from "./ogc.actions";

export interface OGCStateModel {
    loadedGroups: GroupItem<OGC>[];
    unloadingGroups: GroupItem<OGC>[];
    loadingLayers: ListItem<OGC>[];
    unloadingLayers: ListItem<OGC>[];
    focusedLayer: ListItem<OGC>;
}

export const OGC_STATE_MODEL_TOKEN = new StateToken<OGCStateModel>('OGC');

@State<OGCStateModel>({
    name: OGC_STATE_MODEL_TOKEN,
    defaults: {
        loadedGroups: [],
        unloadingGroups: [],
        loadingLayers: [],
        unloadingLayers: [],
        focusedLayer: null
    }
})
@Injectable()
export class OGCState implements NgxsOnInit {
    private OGC_INITIAL_ZINDEX: number = 20;

    ngxsOnInit(ctx: StateContext<any>): void {
        console.log('State initialized, now setting all loaded items to unloaded in case some data is loaded from store');

        const state: OGCStateModel = ctx.getState();
        ctx.setState(
            patch<OGCStateModel>({
                loadedGroups: state.loadedGroups.map(group => {
                    const updatedGroup = {
                        ...group, loaded: 'unloaded', expanded: false, children: group.children.map(
                            child => {
                                const updatedChild  = { ...child, loaded: 'unloaded', show: false };
                                return updatedChild;
                            }
                        )
                    };
                    return updatedGroup as GroupItem<OGC>;
                }),
                unloadingGroups: [],
                loadingLayers: [],
                unloadingLayers: [],
                focusedLayer: null
            })
        )
    }

    static findGroup(id: string) {
        return createSelector([OGCState], (state: OGCStateModel) => {
            return state.loadedGroups.find(group => group.id === id);
        });
    }

    @Selector([OGC_STATE_MODEL_TOKEN])
    static loadedGroups(state: OGCStateModel): GroupItem<OGC>[] {
        return state.loadedGroups;
    }

    @Selector([OGC_STATE_MODEL_TOKEN])
    static unloadingGroups(state: OGCStateModel): GroupItem<OGC>[] {
        return state.unloadingGroups;
    }

    @Selector([OGC_STATE_MODEL_TOKEN])
    static focusedLayer(state: OGCStateModel): ListItem<OGC> {
        return state.focusedLayer;
    }

    @Selector([OGC_STATE_MODEL_TOKEN])
    static loadingLayers(state: OGCStateModel): ListItem<OGC>[] {
        return state.loadingLayers;
    }

    @Selector([OGC_STATE_MODEL_TOKEN])
    static unloadingLayers(state: OGCStateModel): ListItem<OGC>[] {
        return state.unloadingLayers;
    }

    constructor(@Inject(ZIndexService) private zIndexService: ZIndexService) {
        console.log(this)
    }

    @Action(Group.Load)
    loadGroup(ctx: StateContext<OGCStateModel>, {payload}: Group.Load) {
        const { loadedGroups } = ctx.getState();
        const indexedPayload = this.zIndexService.recalculateGroupIndex([payload, ...loadedGroups], this.OGC_INITIAL_ZINDEX);
        const addedItem = indexedPayload[0];
        ctx.setState(
            patch({
                loadedGroups: insertItem(addedItem, 0)
            })
        );
    }

    @Action(Group.Unload)
    unloadGroup(ctx: StateContext<OGCStateModel>, { payload }: Group.Unload) {
        ctx.setState(
            patch({
                loadedGroups: removeItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.item.id)
            })
        );
    }

    @Action(Group.Expand)
    expandGroup(ctx: StateContext<OGCStateModel>, { payload }: Group.Expand) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.item.id, patch({ expanded: true }))
            })
        );
    }

    @Action(Group.Close)
    closeGroup(ctx: StateContext<OGCStateModel>, { payload }: Group.Close) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.item.id, patch({ expanded: false }))
            })
        );
    }

    @Action(Group.Set)
    setGroups(ctx: StateContext<OGCStateModel>, { payload }: Group.Set) {
        const indexedPayload = this.zIndexService.recalculateGroupIndex(payload, this.OGC_INITIAL_ZINDEX);
        const state = ctx.getState();
        ctx.setState({
            ...state,
            loadedGroups: indexedPayload
        });
    }

    @Action(Group.SetLoadStatus)
    setGroupLoadStatus(ctx: StateContext<OGCStateModel>, { payload }: Group.SetLoadStatus) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.group.id, patch({ loaded: payload.status }))
            })
        );
    }

    @Action(Group.Layer.Set)
    setGroupChildren(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.Set) {
        const indexedPayload = this.zIndexService.recalculateItemIndex(payload.children, payload.group.zIndex);

        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.group.item.id, patch({
                    children: indexedPayload
                }))
            })
        );
    }

    @Action(Group.Show)
    showGroup(ctx: StateContext<OGCStateModel>, { payload }: Group.Show) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.item.id, patch({ ...payload, show: true }))
            })
        );
    }

    @Action(Group.Hide)
    hideGroup(ctx: StateContext<OGCStateModel>, { payload }: Group.Hide) {
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === payload.item.id, patch({ ...payload, show: false }))
            })
        );
    }

    @Action(Group.Layer.Show)
    showGroupChild(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.Show) {
        const { group, layer } = payload;
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === group.item.id,
                    patch({
                        children: updateItem<ListItem<OGC>>(child => 
                            child.item.id === layer.item.id,
                            patch({ show: true })
                        )
                    }),
                ),
            }),
        );
    }

    @Action(Group.Layer.Hide)
    hideGroupChild(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.Hide) {
        const { group, layer } = payload;
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === group.item.id,
                    patch({
                        children: updateItem<ListItem<OGC>>(child => 
                            child.item.id === layer.item.id,
                            patch({ show: false })
                        )
                    }),
                ),
            }),
        );
    }

    @Action(Group.Layer.SetLoadStatus)
    loadGroupChild(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.SetLoadStatus) {
        const { layer } = payload;
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.id === layer.parentId,
                    patch({
                        children: updateItem<ListItem<OGC>>(child => 
                            child.item.id === layer.item.id,
                            patch({ loaded: payload.status })
                        )
                    }),
                ),
            }),
        );
    }

    // @Action(Group.Layer.Style.Add)
    // addLayerStyle(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.Style.Add) {
    //     const { layer, style, position } = payload;
    //     ctx.setState(
    //         patch({
    //             loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === ogc.item.parentId,
    //                 patch({
    //                     children: updateItem<ListItem<OGC>>(child => 
    //                         child.item.id === layer.item.id,
    //                         patch({ 
    //                             flatStyles: position && position === 'start'
    //                                 ? insertItem(style, 0)
    //                                 : append([style]) 
    //                         })
    //                     )
    //                 }),
    //             ),
    //         }),
    //     );
    // }

    // @Action(Group.Layer.Style.Modify)
    // modifyLayerStyle(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.Style.Modify) {
    //     const { layer, style } = payload;
    //     const state = ctx.getState();
    //     ctx.setState(
    //         patch({
    //             loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === layer.parentId,
    //                 patch({
    //                     children: updateItem<ListItem<OGC>>(child => 
    //                         child.item.id === layer.item.id,
    //                         patch({ 
    //                             flatStyles: [style]
    //                         })
    //                     )
    //                 }),
    //             ),
    //         }),
    //     );
    // }

    // @Action(Group.Layer.Style.Remove)
    // removeLayerStyle(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.Style.Remove) {
    //     const { layer, style } = payload;
    //     ctx.setState(
    //         patch({
    //             loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === layer.parentId,
    //                 patch({
    //                     children: updateItem<ListItem<OGC>>(child => 
    //                         child.item.id === layer.item.id,
    //                         patch({ 
    //                             flatStyles: removeItem(fStyle => fStyle === style)
    //                         })
    //                     )
    //                 }),
    //             ),
    //         }),
    //     );
    // }

    // @Action(Group.Layer.Style.Set)
    // setLayerStyles(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.Style.Set) {
    //     const { layer, styles } = payload;
    //     ctx.setState(
    //         patch({
    //             loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === layer.parentId,
    //                 patch({
    //                     children: updateItem<ListItem<OGC>>(child => 
    //                         child.item.id === layer.item.id,
    //                         patch({ flatStyles: styles })
    //                     )
    //                 }),
    //             ),
    //         }),
    //     );
    // }

    @Action(Group.Layer.SetFilterStyles)
    setFilterLayerColor(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.SetFilterStyles) {
        const { layer, filters } = payload;
        ctx.setState(
            patch({
                loadedGroups: updateItem<GroupItem<any, OGC>>(ogc => ogc.item.id === layer.parentId,
                    patch({
                        children: updateItem<ListItem<OGC>>(child => 
                            child.item.id === layer.item.id,
                            patch({ filterStyles: filters })
                        )
                    }),
                ),
            }),
        );
    }

    @Action(Group.AppendUnloading)
    appendUnloadingGroups(ctx: StateContext<OGCStateModel>, { payload }: Group.AppendUnloading) {
        const { layers } = payload;
        ctx.setState(
            patch({
                unloadingGroups: append(layers)
            })
        );
    }

    @Action(Group.RemoveUnloading)
    removeUnloadingGroups(ctx: StateContext<OGCStateModel>, { payload }: Group.RemoveUnloading) {
        const { layers } = payload;
        const state = ctx.getState();
        ctx.setState({
            ...state,
            unloadingGroups: state.unloadingGroups.filter(item => ! layers.find(layer => item === layer))
        })
    }

    @Action(Group.Layer.Focus)
    focusGroupChild(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.Focus) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            focusedLayer: payload ? { ...payload } : null// create a copy to always fire the event
        });
    }

    @Action(Group.Layer.AppendLoading)
    appendLoadingItems(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.AppendLoading) {
        const { layers } = payload;
        ctx.setState(
            patch({
                loadingLayers: append(layers)
            })
        );
    }

    @Action(Group.Layer.RemoveLoading)
    removeLoadingItems(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.RemoveLoading) {
        const { layers } = payload;
        const state = ctx.getState();
        ctx.setState({
            ...state,
            loadingLayers: state.loadingLayers.filter(item => ! layers.find(layer => item === layer))
        })
    }

    @Action(Group.Layer.AppendUnloading)
    appendUnloadingItems(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.AppendUnloading) {
        const { layers } = payload;
        ctx.setState(
            patch({
                loadingLayers: append(layers)
            })
        );
    }

    @Action(Group.Layer.RemoveUnloading)
    removeUnloadingItems(ctx: StateContext<OGCStateModel>, { payload }: Group.Layer.RemoveUnloading) {
        const { layers } = payload;
        const state = ctx.getState();
        ctx.setState({
            ...state,
            loadingLayers: state.loadingLayers.filter(item => ! layers.find(layer => item === layer))
        })
    }
}
