export interface AuthToken {
    accessToken: string;
    refreshToken: string;
    tokenType: string;
    expiresIn: number;
}

export class AuthTokenImpl implements AuthToken {
    accessToken: string;
    refreshToken: string;
    tokenType: string;
    expiresIn: number;

    constructor(token: AuthToken) {
        this.accessToken = token.accessToken;
        this.refreshToken = token.refreshToken;
        this.tokenType = token.tokenType;
        this.expiresIn = token.expiresIn;
    }
}

export interface StateAuthToken extends Omit<AuthToken, 'expiresIn'> {
    expiresAt: number;
}

export interface DBLogin {
    id: string;
    name: string;
    password?: string;
    email: string;
    token: AuthToken;
    active: boolean;
}

export interface Login {
    id: string;
    userName: string;
    password?: string;
    email: string;
    token: AuthToken;
    active: boolean;
}

export interface StateLogin extends Omit<Login, 'token'> {
    token: StateAuthToken;
}

export class LoginImpl implements Login {
    id: string;
    userName: string;
    password?: string;
    email: string;
    token: AuthToken;
    active: boolean;

    constructor(login: Login | DBLogin) {
        this.id = login.id;
        this.userName = (login as DBLogin).name || (login as Login).userName;
        this.password = login.password;
        this.email = login.email;
        this.token = new AuthTokenImpl(login.token);
        this.active = login.active;
    }

    getFormat(): Login {
        return {...this};
    }

    getStoreFormat(): StateLogin {
        return {
            ...this,
            token: {
                tokenType: this.token.tokenType,
                refreshToken: this.token.refreshToken,
                accessToken: this.token.accessToken,
                expiresAt: Date.now() + (this.token.expiresIn * 1000),
            } as StateAuthToken
        }
    }
    
}