import { TuiBlockStatus } from "@taiga-ui/layout";
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TuiLink, TuiButton } from '@taiga-ui/core';

@Component({
    selector: 'app-not-found',
    template: `
        <tui-block-status>
            <img
                i18n-alt
                tuiSlot="top"
                alt="Not found"
                src="./assets/images/not-found.svg"
                class="image"
            />
        
            <h4 i18n>Not found</h4>
            <p>
                <a
                    i18n
                    tuiLink
                    type="button"
                    class="tui-space_right-3 tui-space_bottom-3"
                    iconStart="@tui.home"
                    
                    [routerLink]="['/viewer']"
                >
                    Take me somewhere safe
                </a>
            </p>
        </tui-block-status>
    `,
    imports: [
        TuiBlockStatus,
        RouterLink,
        TuiLink,
        TuiButton
    ],
    standalone: true
})

export class NotFoundComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}