import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';

/**
 * TODO: create the service which is going to manage the session (and if a toker or cookie is going to be issued).
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(this.handleErrors);
    }

    handleErrors(source: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> {
        return source.pipe(
            catchError((error: HttpErrorResponse) => {
                return error.status === 401 ? this.handle401(error) : throwError(() => error);
            })
        );
    }

    handle401(error: HttpErrorResponse): Observable<any> {
        // TODO: which parameters are going to be used to detect that a session is not valid anymore?
        // this.router.navigate(['login']);
        // TODO: navigate to Login with an error message explaining that the failure.
        // this.router.navigate(['login'], ['errors']);
        return EMPTY;
    }
}
