import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { LayerMetadata } from "src/app/core/models/layer-metadata";
import { ListItem } from "src/app/core/models/list-item";
import { baseLayerMetadataRepository } from "../repository/base-layer-repository";
import { SetBaseLayer, SetLayers, SetWeatherLayers } from "./layer.actions";

interface LayerStateModel {
    baseLayer: ListItem<LayerMetadata>;
    weatherLayers: ListItem<LayerMetadata>[];
    layers: string[];
}

const LAYER_STATE_TOKEN = new StateToken<LayerStateModel>('Layer');
const BASE_LAYER = baseLayerMetadataRepository.find(layer => layer.id === 'roads');

@State({
    name: LAYER_STATE_TOKEN,
    defaults: {
        baseLayer: { id: BASE_LAYER.id, item: BASE_LAYER, show: true, loaded: 'loaded', parentId: null },
        weatherLayers: [],
        layers: ['relief'],
    }
})
@Injectable()
export class LayerState {
    @Selector()
    static layers(state: LayerStateModel): string[] {
      return state.layers;
    }

    @Selector()
    static baseLayer(state: LayerStateModel): ListItem<LayerMetadata> {
        return state.baseLayer;
    }

    @Selector()
    static weatherLayers(state: LayerStateModel): ListItem<LayerMetadata>[] {
        return state.weatherLayers;
    }

    @Action(SetLayers)
    setLayer(ctx: StateContext<LayerStateModel>, action: SetLayers) {
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            layers: action.payload.layers
        });
    }

    @Action(SetBaseLayer)
    setBaseLayer(ctx: StateContext<LayerStateModel>, action: SetBaseLayer) {
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            baseLayer: action.payload
        })
    }

    @Action(SetWeatherLayers)
    setWeatherLayers(ctx: StateContext<LayerStateModel>, { payload }: SetWeatherLayers) {
        const state = ctx.getState();
        return ctx.setState({
            ...state,
            weatherLayers: payload
        });
    }


}
