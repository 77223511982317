import { Injectable } from '@angular/core';
import { Observable, throwError, of, map, pluck } from 'rxjs';
import { environment } from '../../../../environments/environment';

import { Apollo } from 'apollo-angular';
import { LOGIN, REFRESH_TOKEN, VALITATE_TOKEN } from './auth-queries';
import { CONFIRM_USER, CREATE_USER } from './auth-mutations';
import { LoginImpl, StateAuthToken, StateLogin } from 'src/app/core/models/auth';
import { LoginResponse, RefreshTokenResponse, SignupResponse, TokenResponse } from './auth-responses';
import { ForgotPassword } from 'src/app/core/models/forgot-password';
import { ForgotPasswordConfirmationResponse } from 'src/app/account-recovery/graphql/account-recovery-responses';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly endpoint = `${environment.API}/user`;

  constructor(
    private apollo: Apollo
  ) { }

  login(payload: { userName: string, password: string }): Observable<StateLogin> {
    const { userName, password } = payload;
    if (!userName || !password) {
      return throwError(() => $localize`Username or password is missing`);
    }

    const postData = {
      userName,
      password
    };

    return this.apollo.query<LoginResponse>({
      query: LOGIN,
      variables: postData
    }).pipe(
      pluck('data', 'logginUser'),
      map(resp => new LoginImpl(resp).getStoreFormat())
    )
  }

  validateToken(token: string): Observable<any> {
    return this.apollo.query<TokenResponse>({ query: VALITATE_TOKEN, variables: { token }}).pipe(
      pluck('data', 'validateTokenUser'),
    );
  }

  refreshToken(payload: { token: string, userName: string }): Observable<any> {
    return this.apollo.query<RefreshTokenResponse>({ query: REFRESH_TOKEN, variables: payload}).pipe(
      pluck('data', 'refreshTokenUser'),
    );
  }

  logout(token: StateAuthToken): Observable<void> {
    return of(null);
  }

  signup(payload: {
    email: string, userName: string, password: string
  }): Observable<ForgotPassword> {
    const { userName, email, password} = payload;
    const postData = { userName, email, password };
    return this.apollo.mutate<SignupResponse>({ mutation: CREATE_USER, variables: postData }).pipe(
      pluck('data', 'createUser')
    );
  }

  sendSignupCode(payload: {
    userName?: string,
    phone?: string
  }): Observable<any> {
    const { userName, phone } = payload;

    return of(null);
  }

  confirmUser(payload: {
    code: string,
    userName?: string,
    email?: string
  }): Observable<any> {
    return this.apollo.mutate<ForgotPasswordConfirmationResponse>({
      mutation: CONFIRM_USER,
      variables: {
        code: payload.code,
        userName: payload.userName
      }
    }).pipe(
      pluck('data', 'confirmationForgotPasswordUser'),
    );
  }
}
