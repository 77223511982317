import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { BusyInterceptor } from './busy.interceptor';
import { EnsureSSLInterceptor } from './ensure-ssl.interceptor';

/**
 * https://angular.io/guide/http#http-interceptors
 *
 * It's important to put the interceptors in the same order that are going to be called.
 */
export const httpInterceptorProviders = [
    // { provide: HTTP_INTERCEPTORS, useClass: EnsureSSLInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true },
];
