import { provideAnimations } from "@angular/platform-browser/animations";
import { tuiButtonOptionsProvider, tuiNotificationOptionsProvider } from "@taiga-ui/core";
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from "@angular/router";
import { provideHttpClient } from "@angular/common/http";
import { APP_ROUTES } from "./routes";
import { NgxsModule } from "@ngxs/store";
import { AUTH_STATE_TOKEN, AuthState } from "./core/state/auth.state";
import { THEME_STATE_TOKEN, ThemeState } from "./shared/state/theme.state";
import { environment } from "src/environments/environment";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { LOCAL_STORAGE_ENGINE, NgxsStoragePluginModule, SESSION_STORAGE_ENGINE } from "@ngxs/storage-plugin";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { GraphQLModule } from "./graphql.module";
import { SAVER, getSaver } from "./core/services/saver.provider";
import { TUI_VALIDATION_ERRORS } from "@taiga-ui/kit";
import { TAIGA_CUSTOM_VALIDATOR_ERRORS } from "./core/constants/taiga-custom-validator-errors";
import { NG_EVENT_PLUGINS } from "@taiga-ui/event-plugins";
import { OGC_STATE_MODEL_TOKEN } from "./ogc/state/ogc.state";
import { PROJECT_STATE_MODEL_TOKEN } from "./plantation/state/project.state";
import { SKETCH_STATE_MODEL_TOKEN } from "./sketch/state/sketch.state";

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    importProvidersFrom([
      NgxsModule.forRoot([AuthState, ThemeState], {
        developmentMode: !environment.production
      }),
      NgxsStoragePluginModule.forRoot({
        key: [{
          key: AUTH_STATE_TOKEN,
          engine: LOCAL_STORAGE_ENGINE,
        }, 
        THEME_STATE_TOKEN,
        {
          key: OGC_STATE_MODEL_TOKEN,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: PROJECT_STATE_MODEL_TOKEN,
          engine: LOCAL_STORAGE_ENGINE,
        },
        {
          key: SKETCH_STATE_MODEL_TOKEN,
          engine: LOCAL_STORAGE_ENGINE,
        },]
      }),
      NgxsRouterPluginModule.forRoot(),
      NgxsReduxDevtoolsPluginModule.forRoot(),
      GraphQLModule,
    ]),
    tuiNotificationOptionsProvider({
    }),
    provideRouter(APP_ROUTES),
    provideHttpClient(),
    // {provide: TUI_SANITIZER, useClass: NgDompurifySanitizer},
    { provide: SAVER, useFactory: getSaver },
    { provide: TUI_VALIDATION_ERRORS, useValue: TAIGA_CUSTOM_VALIDATOR_ERRORS },
    NG_EVENT_PLUGINS,
    tuiButtonOptionsProvider({size: 'm'}),
  ]
};
