import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../state/auth.state';

@Injectable({providedIn: 'root'})
export class AuthGuard  {
    constructor(private store: Store) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
        return isAuthenticated;
    }
}
