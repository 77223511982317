export class SetPath {
    static readonly type = '[Sidebar] Set Path';
    constructor(public payload: string) {}
}

export class SetBasePath {
    static readonly type = '[Sidebar] Set Base Path';
    constructor(public payload: string) {}
}

export class OpenSidebar {
    static readonly type = '[Sidebar] Open Sidebar';
    constructor() {}
}

export class CloseSidebar {
    static readonly type = '[Sidebar] Close Sidebar';
    constructor() {}
}

export class ResizeSidebar {
    static readonly type = '[Sidebar] Resize Sidebar';
    constructor(public payload: 'l' | 's' | 'm') {}
}
