import { BingMapsSourceMetadata, LayerMetadata, OSMSourceMetadata, StamenSourceMetadata, XYZSourceMetadata } from "src/app/core/models/layer-metadata";

export const baseLayerMetadataRepository: LayerMetadata[] = [
    {
        id: 'topological',
        name: $localize`topological`,
        type: 'tile',
        source: {
            type: 'xyz',
            url: 'https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=15e95255aeb24c068e95fb0a0269013c',
            crossOrigin: null
        } as XYZSourceMetadata
    },
    {
        id: 'roads',
        name: $localize`roads`,
        type: 'tile',
        source: {
            type: 'osm',
        } as OSMSourceMetadata
    },
    // {
    //     id: 'relief',
    //     name: $localize`relief`,
    //     type: 'tile',
    //     source: {
    //         type: 'stamen',
    //         layer: 'terrain'
    //     } as StamenSourceMetadata
    // },
    // {
    //     id: 'curves',
    //     name: $localize`curves`,
    //     type: 'tile',
    //     source: {
    //         type: 'osm',
    //     } as OSMSourceMetadata
    // },
    {
        id: 'satellite',
        name: $localize`satellite`,
        type: 'tile',
        source: {
            type: 'bing',
            key: 'AngHNLLaw82hmIrJc_O7uZThEyN2OPuq60dc2fASQZ_OPdv0r6Nd3mdOj8OLzZcY',
            imagerySet: 'Aerial',
            culture: 'es-mx'
        } as BingMapsSourceMetadata
    },
];
