import { Coordinate } from "ol/coordinate";
import { ProjectionLike } from "ol/proj";
import { FeatureStyle, FeatureStyles } from "src/app/core/models/feature-style";
import { ListItem } from "src/app/core/models/list-item";
import { MapInteractionTool } from "src/app/core/models/map-interaction-tool";
import { OGC } from "src/app/core/models/ogc";

export class SetTool {
  static readonly type = '[ViewerMap] Set Tool';
  constructor(public payload: { tool: MapInteractionTool | null }) {}
}

export class SetProjection {
  static readonly type = '[ViewerMap] Set Projection';
  constructor(public payload: { projection: ProjectionLike | null }) {}
}

export class FitLayer {
  static readonly type = '[ViewerMap] Fit Layer';
  constructor(public payload: ListItem<OGC>) {}
}

export class ActivateLayer {
  static readonly type = '[ViewerMap] Activate Layer';
  constructor(public payload: ListItem<OGC>) {}
}

export class SetOverlayCoordinates {
  static readonly type = '[ViewerMap] Set Overlay Coordinates';
  constructor(public payload: Coordinate) {}
}

export class SetDefaultFeatureStyle {
  static readonly type = '[ViewerMap] Set Default Feature Style';
  constructor(public payload: { type: 'sketch' | 'ogc', style: FeatureStyles }) {}
}

export class SetDefaultFeatureHoverStyle {
  static readonly type = '[ViewerMap] Set Default Feature Hover Style';
  constructor(public payload: { type: 'sketch' | 'ogc', style: FeatureStyles }) {}
}

export class SetDefaultFeatureActiveStyle {
  static readonly type = '[ViewerMap] Set Default Feature Active Style';
  constructor(public payload: { type: 'sketch' | 'ogc', style: FeatureStyles }) {}
}

export class SetFeatureCacheEnabled {
  static readonly type = '[ViewerMap] Set Feature Cache';
  constructor(public payload: boolean) {}
}