import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { Modify } from "./feature-modify.actions";
import { FeatureModify } from "src/app/core/models/modify";
import { append, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { GeoJSONGeometry } from "src/app/core/models/geojson";
import { GeoJSONFeature } from "ol/format/GeoJSON";


interface FeatureModifyStateModel {
  layers: FeatureModify[];
  activeLayer: string | number;
  drawing: GeoJSONGeometry;
}

const FEATURE_MODIFY_STATE_TOKEN = new StateToken<FeatureModifyStateModel>('FeatureModify');

@State({
    name: FEATURE_MODIFY_STATE_TOKEN,
    defaults: {
      activeLayer: null,
      layers: [],
      drawing: null
    }
})
@Injectable()
export class FeatureModifyState {
    @Selector()
    static activeLayer(state: FeatureModifyStateModel): string | number {
      return state.activeLayer;
    }

    @Selector()
    static layers(state: FeatureModifyStateModel): any[] {
      return state.layers;
    }

    @Selector()
    static drawingGeometry(state: FeatureModifyStateModel): GeoJSONGeometry {
      return state.drawing;
    }

    @Action(Modify.Add)
    addLayer(ctx: StateContext<FeatureModifyStateModel>, {payload}: Modify.Add) {
        ctx.setState(
          patch({
            layers: append([payload.feature])
          })
        );
    }
  
    @Action(Modify.Remove)
    removeLayer(ctx: StateContext<FeatureModifyStateModel>, {payload}: Modify.Remove) {
        ctx.setState(
            patch({
              layers: removeItem<FeatureModify>(layerItem => layerItem.layerId === payload.layerId)
            })
        );
    }
  
    @Action(Modify.Modify)
    modifyLayer(ctx: StateContext<FeatureModifyStateModel>, { payload }: Modify.Modify) {
      ctx.setState(
        patch({
          layers: updateItem<FeatureModify>(layerItem => layerItem.layerId === payload.layerId,
            patch({
              features: payload.feature.features
            })
          )
        })
      );
    }

    @Action(Modify.AddGeometry)
    addGeometry(ctx: StateContext<FeatureModifyStateModel>, { payload }: Modify.AddGeometry) {
      ctx.setState(
        patch({
          layers: updateItem<FeatureModify>(layerItem => layerItem.layerId === payload.layerId,
            patch({
              features: append(payload.feature.features)
            })
          )
        })
      );
    }

    @Action(Modify.UpdateGeometry)
    updateGeometry(ctx: StateContext<FeatureModifyStateModel>, { payload }: Modify.UpdateGeometry) {
      ctx.setState(
        patch({
          layers: updateItem<FeatureModify>(layerItem => layerItem.layerId === payload.layerId,
            patch({
              features: updateItem<GeoJSONGeometry>(geometryItem => geometryItem.id === payload.feature.id, payload.feature)
            })
          )
        })
      );
    }

    // @Action(Modify.UpdateGeometries)
    // updateGeometries(ctx: StateContext<FeatureModifyStateModel>, { payload }: Modify.UpdateGeometries) {
    //   ctx.setState(
    //     patch({
    //       layers: updateItem<FeatureModify>(layerItem => layerItem.layerId === payload.layerId,
    //         patch({
    //           features: updateItem<GeoJSONGeometry>(geometryItem => geometryItem.id === payload.feature.id, payload.feature)
    //         })
    //       )
    //     })
    //   );
    // }

    @Action(Modify.RemoveGeometry)
    removeGeometry(ctx: StateContext<FeatureModifyStateModel>, { payload }: Modify.RemoveGeometry) {
      ctx.setState(
        patch({
          layers: updateItem<FeatureModify>(layerItem => layerItem.layerId === payload.layerId,
            patch({
              features: removeItem<GeoJSONGeometry>(geometryItem => geometryItem.id === payload.featureId)
            })
          )
        })
      );
    }
  
    @Action(Modify.Set)
    setLayer(ctx: StateContext<FeatureModifyStateModel>, { payload }: Modify.Set) {
      const state = ctx.getState();
      ctx.setState({...state, layers: payload.layers});
    }

    @Action(Modify.SetActiveLayer)
    setActiveLayer(ctx: StateContext<FeatureModifyStateModel>, { payload }: Modify.SetActiveLayer) {
      const state = ctx.getState();
      ctx.setState({...state, activeLayer: payload.layer});
    }

    @Action(Modify.SetDrawingGeometry)
    setDrawingGeometry(ctx: StateContext<FeatureModifyStateModel>, {payload}: Modify.SetDrawingGeometry) {
      const state = ctx.getState();
      ctx.setState({...state, drawing: payload});
    }
}