import { LayerMetadata } from "src/app/core/models/layer-metadata";
import { ListItem } from "src/app/core/models/list-item";

export class SetLayers {
    static readonly type = '[Layers] Set Layers';
    constructor(public payload: { layers: string[] }) {}
}

export class SetBaseLayer {
    static readonly type = '[Layers] Set Base Layer';
    constructor(public payload: ListItem<LayerMetadata>) {}
}

export class SetWeatherLayers {
    static readonly type = '[Layers] Set Weather Layers';
    constructor(public payload: ListItem<LayerMetadata>[]) {}
}