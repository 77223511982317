import { FeatureStyle } from "src/app/core/models/feature-style";
import { AppendableListItem, FilterStyle, GroupItem, ListItem, LoadStatus, ProjectItem } from "src/app/core/models/list-item";
import { OGC } from "src/app/core/models/ogc";
import { Project as IProject } from "src/app/core/models/project";

export namespace Project {
    export class Load {
        static readonly type = '[Project] Load Project';
        constructor(public payload: ProjectItem<IProject, OGC>) {}
    }

    export class Modify {
        static readonly type = '[Project] Modify Project';
        constructor(public payload: {projectId: string, project: ProjectItem<IProject, OGC>}) {}
    }

    export class Unload {
        static readonly type = '[Project] Unload Project';
        constructor(public payload: ProjectItem<OGC>) {}
    }
    
    export class Expand {
        static readonly type = '[Project] Expand Project';
        constructor(public payload: ProjectItem<OGC>) {}
    }
    
    export class Close {
        static readonly type = '[Project] Close Project';
        constructor(public payload: ProjectItem<OGC>) {}
    }
    
    export class Show {
        static readonly type = '[Project] Show Project';
        constructor(public payload: ProjectItem<OGC>) {}
    }
    
    export class Hide {
        static readonly type = '[Project] Hide Project';
        constructor(public payload: ProjectItem<OGC>) {}
    }

    export class Set {
        static readonly type = '[Project] Set Projects';
        constructor(public payload: ProjectItem<IProject, OGC>[]) {}
    }

    export class AppendUnloading {
        static readonly type = '[Project] Append Unloading Projects';
        constructor(public payload: { layers: ProjectItem<OGC>[] }) {}
    }
    
    export class RemoveUnloading {
        static readonly type = '[Project] Remove Unloading Projects';
        constructor(public payload: { layers: ProjectItem<OGC>[] }) {}
    }

    export namespace Group {
        export class Load {
            static readonly type = '[Project] Load Group';
            constructor(public payload: GroupItem<OGC>) {}
        }
        
        export class Unload {
            static readonly type = '[Project] Unload Group';
            constructor(public payload: GroupItem<OGC>) {}
        }
        
        export class Expand {
            static readonly type = '[Project] Expand Group';
            constructor(public payload: GroupItem<OGC>) {}
        }
        
        export class Close {
            static readonly type = '[Project] Close Group';
            constructor(public payload: GroupItem<OGC>) {}
        }
        
        export class Set {
            static readonly type = '[Project] Set Groups';
            constructor(public payload: GroupItem<OGC>[]) {}
        }
        
        export class Show {
            static readonly type = '[Project] Show Group';
            constructor(public payload: GroupItem<OGC>) {}
        }
        
        export class Hide {
            static readonly type = '[Project] Hide Group';
            constructor(public payload: GroupItem<OGC>) {}
        }

        export class AppendUnloading {
            static readonly type = '[Project] Append Unloading Groups';
            constructor(public payload: { layers: GroupItem<OGC>[] }) {}
        }
        
        export class RemoveUnloading {
            static readonly type = '[Project] Remove Unloading Groups';
            constructor(public payload: { layers: GroupItem<OGC>[] }) {}
        }

        export class SetLoadStatus {
            static readonly type = '[Project] Set Group Load Status';
            constructor(public payload: { group: GroupItem<OGC>, status: LoadStatus }) {}
        }

        export namespace Layer {
            export class Set {
                static readonly type = '[Project] Update Group Layers';
                constructor(public payload: { group: GroupItem<OGC>, children: ListItem<OGC>[]}) {}
            }
            
            export class Append {
                static readonly type = '[Project] Add Group Layer';
                constructor(public payload: { group: GroupItem<OGC>, children: ListItem<OGC>[]}) {}
            }
            
            export class Show {
                static readonly type = '[Project] Show Group layer';
                constructor(public payload: { group: GroupItem<OGC>, layer: ListItem<OGC> }) {}
            }
            
            export class Hide {
                static readonly type = '[Project] Hide Group layer';
                constructor(public payload: { group: GroupItem<OGC>, layer: ListItem<OGC> }) {}
            }
            
            export class SetLoadStatus {
                static readonly type = '[Project] Set Load Status Group layer';
                constructor(public payload: { layer: ListItem<OGC>, status: LoadStatus }) {}
            }
            
            export class Focus {
                static readonly type = '[Project] Focus Group layer';
                constructor(public payload: ListItem<OGC>) {}
            }
            
            export class SetStyles {
                static readonly type = '[Project] Set Layer Styles';
                constructor(public payload: { styles: FeatureStyle[], group: GroupItem<any, OGC>, layer: ListItem<OGC> }) {}
            }
            
            export class SetFilterStyles {
                static readonly type = '[Project] Set Layer Filter Styles';
                constructor(public payload: { filters: FilterStyle[], group: GroupItem<any, OGC>, layer: ListItem<OGC> }) {}
            }
            
            export class AppendLoading {
                static readonly type = '[Project] Append Loading layers';
                constructor(public payload: { layers: AppendableListItem<OGC>[] }) {}
            }
            
            export class RemoveLoading {
                static readonly type = '[Project] Remove Loading layers';
                constructor(public payload: { layers: ListItem<OGC>[] }) {}
            }
            
            export class AppendUnloading {
                static readonly type = '[Project] Append Unloading layers';
                constructor(public payload: { layers: ListItem<OGC>[] }) {}
            }
            
            export class RemoveUnloading {
                static readonly type = '[Project] Remove Unloading layers';
                constructor(public payload: { layers: ListItem<OGC>[] }) {}
            }
        }
    }


}


