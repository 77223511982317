import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetTheme } from './theme.actions';

export interface ThemeStateModel {
  theme: 'dark' | 'light' | null;
}

export const THEME_STATE_TOKEN = new StateToken<ThemeStateModel>('theme');

@State<ThemeStateModel>({
  name: THEME_STATE_TOKEN,
  defaults: {
    theme: null,
  }
})
@Injectable()
export class ThemeState {
  @Selector()
  static theme(state: ThemeStateModel): 'dark' | 'light' | null {
    return state.theme;
  }

  constructor() {}

  @Action(SetTheme)
  setTheme(ctx: StateContext<ThemeStateModel>, action: SetTheme) {
    return ctx.setState({
      theme: action.payload.theme,
    });
  }
}
