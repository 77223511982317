import { SidebarRoute } from 'src/app/core/models/sidebar-route';

export const sidebarRoutes: SidebarRoute[] = [
    // {
    //     icon: tuiIconCallOut,
    //     path: 'amazon',
    //     title: $localize`Globant`,
    //     // component: TestDetailComponent
    // },
    {
        icon: '@tui.box',
        path: '/viewer/projects',
        title: $localize`Projects`,
        fullscreen: false
        // loadChildren: () => import('../../../plantation/plantation.module').then(m => m.PlantationModule)
    }, {
        icon: '@tui.cloud',
        path: '/viewer/ogc',
        title: $localize`OGC Services`,
        fullscreen: false
        // loadChildren: () => import('../../../ogc/ogc.module').then(m => m.OgcModule)
    },
    // {
    //     icon: tuiIconCloud,
    //     path: 'tools',
    //     title: $localize`tools`,
    //     // loadChildren: () => import('../../../map-tools/map-tools.module').then(m => m.MapToolsModule)
    // },
    {
        icon: '@tui.layers',
        path: '/viewer/layers',
        title: $localize`Layers`,
        fullscreen: false
        // loadChildren: () => import('../../../layer-toggle/layer-toggle.module').then(m => m.LayerToggleModule)
    },
    {
        icon: '@tui.notebook-pen',
        path: '/viewer/sketches',
        title: $localize`Sketches`,
        fullscreen: false
    },
    {
        icon: '@tui.folder-archive',
        path: '/viewer/media',
        title: $localize`Media`,
        fullscreen: false
    },
    {
        icon: '@tui.layout-dashboard',
        path: '/viewer/dashboard',
        title: $localize`Dashboard`,
        fullscreen: true
    }
];

export const sidebarFooterRoutes: SidebarRoute[] = [
    {
        icon: '@tui.settings',
        path: '/viewer/config',
        title: $localize`Configuration`,
        fullscreen: false
        // loadChildren: () => import('../../../user-preferences/user-preferences.module').then(m => m.UserPreferencesModule)
    }
];
