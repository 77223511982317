import { FeatureFilter } from "../feature-filter";

export namespace Filters {
  export class Add {
    static readonly type = '[FeatureFilter] Add Filter';
    constructor(public payload: {layerId: string | number, filter: FeatureFilter}) {}
  }
  
  export class Remove {
    static readonly type = '[FeatureFilter] Remove Filter';
    constructor(public payload: {layerId: string | number, filter: FeatureFilter}) {}
  }
  
  export class Modify {
    static readonly type = '[FeatureFilter] Modify Filter';
    constructor(public payload: {layerId: string | number, filter: FeatureFilter}) {}
  }
  
  export class Set {
    static readonly type = '[FeatureFilter] Set Filters';
    constructor(public payload: {layerId: string | number, filters: FeatureFilter[]}) {}
  }
}

