import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { FitLayer, ActivateLayer, SetDefaultFeatureActiveStyle, SetDefaultFeatureHoverStyle, SetDefaultFeatureStyle, SetProjection, SetTool, SetOverlayCoordinates, SetFeatureCacheEnabled } from "./viewer-map.actions";
import { MapInteractionTool } from "src/app/core/models/map-interaction-tool";
import { ProjectionLike } from "ol/proj";
import { DefaultStyle, DefaultStyles, FeatureStyle, FeatureStyles } from "src/app/core/models/feature-style";
import { STYLE, HOVER_STYLE, ACTIVE_STYLE, FLAT_STYLE, WEBGL_STYLE, FLAT_HOVER_STYLE, WEBGL_HOVER_STYLE, FLAT_ACTIVE_STYLE, WEBGL_ACTIVE_STYLE, SKETCH_STYLE, SKETCH_FLAT_STYLE } from "src/app/core/constants/default-feature-styles";
import { ListItem } from "src/app/core/models/list-item";
import { OGC } from "src/app/core/models/ogc";
import { Coordinate } from "ol/coordinate";


interface ViewerMapStateModel {
    tool: MapInteractionTool;
    projection: ProjectionLike;
    fitLayer: ListItem<OGC>;
    activeLayer: ListItem<OGC>;
    overlayCoordinates: Coordinate;
    defaultStyles: DefaultStyles;
    featureCacheEnabled: boolean
}

const VIEWER_MAP_STATE_TOKEN = new StateToken<ViewerMapStateModel>('ViewerMap');

@State({
    name: VIEWER_MAP_STATE_TOKEN,
    defaults: {
        tool: null,
        projection: 'EPSG:3857',
        fitLayer: null,
        activeLayer: null,
        overlayCoordinates: null,
        defaultStyles: {
            ogc: {
                style: {
                    style: STYLE,
                    flatStyle: FLAT_STYLE,
                    webGLStyle: WEBGL_STYLE
                },
                hover: {
                    style: HOVER_STYLE,
                    flatStyle: FLAT_HOVER_STYLE,
                    webGLStyle: WEBGL_HOVER_STYLE
                },
                active: {
                    style: ACTIVE_STYLE,
                    flatStyle: FLAT_ACTIVE_STYLE,
                    webGLStyle: WEBGL_ACTIVE_STYLE
                }
            },
            sketch: {
                style: {
                    style: SKETCH_STYLE,
                    flatStyle: SKETCH_FLAT_STYLE,
                    webGLStyle: WEBGL_STYLE
                },
                hover: {
                    style: HOVER_STYLE,
                    flatStyle: FLAT_HOVER_STYLE,
                    webGLStyle: WEBGL_HOVER_STYLE
                },
                active: {
                    style: ACTIVE_STYLE,
                    flatStyle: FLAT_ACTIVE_STYLE,
                    webGLStyle: WEBGL_ACTIVE_STYLE
                }
            }
        },
        featureCacheEnabled: false
    }
})
@Injectable()
export class ViewerMapState {
    @Selector()
    static tool(state: ViewerMapStateModel): MapInteractionTool | null {
      return state.tool;
    }

    @Selector()
    static projection(state: ViewerMapStateModel): ProjectionLike | null {
      return state.projection;
    }

    @Selector()
    static fitLayer(state: ViewerMapStateModel): ListItem<OGC> | null {
      return state.fitLayer;
    }

    @Selector()
    static activeLayer(state: ViewerMapStateModel): ListItem<OGC> | null {
      return state.activeLayer;
    }

    @Selector()
    static overlayCoordinates(state: ViewerMapStateModel): Coordinate | null {
      return state.overlayCoordinates;
    }

    @Selector()
    static defaultOgcStyles(state: ViewerMapStateModel): DefaultStyle {
      return state.defaultStyles.ogc;
    }

    @Selector()
    static defaultSketchStyles(state: ViewerMapStateModel): DefaultStyle {
      return state.defaultStyles.sketch;
    }

    @Selector()
    static defaultFeatureStyle(state: ViewerMapStateModel): FeatureStyles {
      return state.defaultStyles.ogc.style;
    }

    @Selector()
    static defaultSketchFeatureStyle(state: ViewerMapStateModel): FeatureStyles {
      return state.defaultStyles.sketch.style;
    }

    @Selector()
    static defaultFeatureHoverStyle(state: ViewerMapStateModel): FeatureStyles {
      return state.defaultStyles.ogc.hover;
    }

    @Selector()
    static defaultSketchFeatureHoverStyle(state: ViewerMapStateModel): FeatureStyles {
      return state.defaultStyles.sketch.hover;
    }

    @Selector()
    static defaultFeatureActiveStyle(state: ViewerMapStateModel): FeatureStyles {
      return state.defaultStyles.ogc.active;
    }

    @Selector()
    static defaultSketchFeatureActiveStyle(state: ViewerMapStateModel): FeatureStyles {
      return state.defaultStyles.sketch.active;
    }

    @Selector()
    static featureCacheEnabled(state: ViewerMapStateModel): boolean {
      return state.featureCacheEnabled;
    }


    // @Action(SetLayer)
    // setLayer(ctx: StateContext<ViewerMapStateModel>, action: SetLayer) {
    //     const state = ctx.getState();
    //     ctx.setState({
    //         ...state,
    //         layer: action.payload.layer
    //     });
    // }

    @Action(SetTool)
    setTool(ctx: StateContext<ViewerMapStateModel>, action: SetTool) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            tool: action.payload.tool
        });
    }

    @Action(SetProjection)
    setProjection(ctx: StateContext<ViewerMapStateModel>, action: SetProjection) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            projection: action.payload.projection
        });
    }

    @Action(FitLayer)
    fitLayer(ctx: StateContext<ViewerMapStateModel>, action: FitLayer) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            fitLayer: {...action.payload} // copy the element to trigger change even on same object
        });
    }

    @Action(ActivateLayer)
    focusLayer(ctx: StateContext<ViewerMapStateModel>, action: ActivateLayer) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            activeLayer: {...action.payload} // copy the element to trigger change even on same object
        });
    }

    @Action(SetOverlayCoordinates)
    setOverlayCoordinates(ctx: StateContext<ViewerMapStateModel>, action: SetOverlayCoordinates) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            overlayCoordinates: action.payload
        });
    }

    @Action(SetDefaultFeatureStyle)
    setDefaultFeatureStyle(ctx: StateContext<ViewerMapStateModel>, {payload}: SetDefaultFeatureStyle) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            defaultStyles: {
                ...state.defaultStyles,
                [payload.type]: {
                    style: payload.style
                }
            }
        });
    }

    @Action(SetDefaultFeatureHoverStyle)
    setDefaultFeatureHoverStyle(ctx: StateContext<ViewerMapStateModel>, {payload}: SetDefaultFeatureHoverStyle) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            defaultStyles: {
                ...state.defaultStyles,
                [payload.type]: {
                    hover: payload.style
                }
            }
        });
    }

    @Action(SetDefaultFeatureActiveStyle)
    setDefaultFeatureActiveStyle(ctx: StateContext<ViewerMapStateModel>, {payload}: SetDefaultFeatureActiveStyle) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            defaultStyles: {
                ...state.defaultStyles,
                [payload.type]: {
                    active: payload.style
                }
            }
        });
    }

    @Action(SetFeatureCacheEnabled)
    setFeatureCacheEnabled(ctx: StateContext<ViewerMapStateModel>, payload: boolean) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            featureCacheEnabled: payload
        });
    }
}