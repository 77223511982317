import { FeatureStyles, LayerStyle } from "src/app/core/models/feature-style";

export namespace Styles {
  export class Add {
    static readonly type = '[FeatureStyle] Add Style';
    constructor(public payload: {layerId: string | number, style: FeatureStyles}) {}
  }
  
  export class Remove {
    static readonly type = '[FeatureStyle] Remove Style';
    constructor(public payload: {layerId: string | number, style: FeatureStyles}) {}
  }
  
  export class Modify {
    static readonly type = '[FeatureStyle] Modify Style';
    constructor(public payload:  {layerId: string | number, style: FeatureStyles}) {}
  }

  export class Set {
    static readonly type = '[FeatureStyle] Set Style';
    constructor(public payload: { layers: LayerStyle[] }) {}
  }
}

