import { Injectable } from "@angular/core";
import { FeatureFilterStateModel } from "../../core/state/feature-filter.state";
import { Action, Selector, State, StateContext, StateToken, createSelector } from "@ngxs/store";
import { FeatureFilter, LayerFilters } from "../feature-filter";
import { append, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { Filters } from "./ogc-feature-filter.actions";

export const OGC_FEATURE_FILTER_TOKEN = new StateToken<OgcFeatureFilterState>('ogcfeaturefilter');

@State<FeatureFilterStateModel>({
  name: OGC_FEATURE_FILTER_TOKEN,
  defaults: {
    layers: []
  }
})
@Injectable()
export class OgcFeatureFilterState {  
  @Selector()
  static layers(state: FeatureFilterStateModel): LayerFilters[] {
    return state.layers;
  }

  static filters(layerId: string | number) {
    return createSelector([OgcFeatureFilterState], (state: FeatureFilterStateModel) => {
      const foundLayer = state.layers.find(item => item.layerId === layerId);
      return foundLayer ? foundLayer.filters : [];
    });
  }

  @Action(Filters.Add)
  addFilter(ctx: StateContext<FeatureFilterStateModel>, {payload}: Filters.Add) {
    const state = ctx.getState();

    if (payload.layerId) {
      const layer = state.layers.find(item => item.layerId === payload.layerId);

      if (! layer) { // Create the new layer item
        const layerFilters: LayerFilters = {
          layerId: payload.layerId,
          filters: [payload.filter],
        };
  
        ctx.setState(
          patch({
            layers: append([layerFilters])
          })
        )
      } else { // update existing layer item
        ctx.setState(
          patch({
              layers: updateItem<LayerFilters>(layerItem => layerItem.layerId === payload.layerId,
                patch({
                  filters: append([payload.filter]),
                })
              )
          })
        );
      }
    }


    // ctx.dispatch(new MapStyles.Add({layer: payload.layer, styles: [payload.filter.style], position: 'start'}));
  }

  @Action(Filters.Remove)
  removeFilter(ctx: StateContext<FeatureFilterStateModel>, {payload}: Filters.Remove) {
      ctx.setState(
          patch({
            layers: updateItem<LayerFilters>(layerItem => layerItem.layerId === payload.layerId,
              patch({
                filters: removeItem<FeatureFilter>(filter => filter.id === payload.filter.id)
              })
            )
          })
      );

    // ctx.dispatch(new MapStyles.Remove({layer: payload.layer, styles: [payload.filter.style]}));

  }

  @Action(Filters.Modify)
  modifyFilter(ctx: StateContext<FeatureFilterStateModel>, { payload }: Filters.Modify) {
    ctx.setState(
      patch({
        layers: updateItem<LayerFilters>(layerItem => layerItem.layerId === payload.layerId,
          patch({
            filters: updateItem<FeatureFilter>(filter => filter.id === payload.filter.id, payload.filter),
          })
        )
      })
    );

    // ctx.dispatch(new MapStyles.Modify({layer: payload.layer, styles: [payload.filter.style]}));
  }

  @Action(Filters.Set)
  setFilter(ctx: StateContext<FeatureFilterStateModel>, {payload}: Filters.Set) {
    const state = ctx.getState();
    const layer = state.layers.find(item => item.layerId === payload.layerId);

    if (! layer) { // Create the new layer item
      const layerFilters: LayerFilters = {
        layerId: payload.layerId,
        filters: payload.filters,
      };

      ctx.setState(
        patch({
          layers: append([layerFilters])
        })
      )
    } else { // update existing layer item
      ctx.setState(
        patch({
            layers: updateItem<LayerFilters>(layerItem => layerItem.layerId === payload.layerId,
              patch({
                filters: payload.filters,
              })
            )
        })
      );
    }

    // ctx.dispatch(new MapStyles.Remove({ layer: payload.layer, styles: layer.filters.map(filter => filter.style) })).pipe(
    //   mergeMap(() => ctx.dispatch(new MapStyles.Add({ layer: payload.layer, styles: payload.filters.map(filter => filter.style), position: 'start' })))
    // );
  }
}