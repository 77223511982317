import { StateAuthToken } from "../models/auth";

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { userName: string, password: string }) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class SetUser {
  static readonly type = '[Auth] Set User';
  constructor(public payload: { userName: string, token: StateAuthToken, active: boolean }) {}
}