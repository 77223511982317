import { FeatureFlatStyle, FeatureStyle, FeatureWebGLStyle } from "src/app/core/models/feature-style";
import { AppendableListItem, FilterStyle, GroupItem, ListItem, LoadStatus, ProjectItem } from "src/app/core/models/list-item";
import { OGC as IOGC } from "src/app/core/models/ogc";

export namespace Group {
    export class Load {
        static readonly type = '[OGC] Load Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }
    
    export class Unload {
        static readonly type = '[OGC] Unload Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }
    
    export class Expand {
        static readonly type = '[OGC] Expand Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }
    
    export class Close {
        static readonly type = '[OGC] Close Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }
    
    export class Set {
        static readonly type = '[OGC] Set Groups';
        constructor(public payload: GroupItem<IOGC>[]) {}
    }
    
    export class Show {
        static readonly type = '[OGC] Show Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }
    
    export class Hide {
        static readonly type = '[OGC] Hide Group';
        constructor(public payload: GroupItem<IOGC>) {}
    }

    export class AppendUnloading {
        static readonly type = '[OGC] Append Unloading Groups';
        constructor(public payload: { layers: GroupItem<IOGC>[] }) {}
    }
    
    export class RemoveUnloading {
        static readonly type = '[OGC] Remove Unloading Groups';
        constructor(public payload: { layers: GroupItem<IOGC>[] }) {}
    }

    export class SetLoadStatus {
        static readonly type = '[OGC] Set Load Status';
        constructor(public payload: { group: GroupItem<IOGC>, status: LoadStatus }) {}
    }

    export namespace Layer {
        export class Set {
            static readonly type = '[OGC] Update Group layers';
            constructor(public payload: { group: GroupItem<IOGC>, children: ListItem<IOGC>[]}) {}
        }
        
        export class Append {
            static readonly type = '[OGC] Update Group layers';
            constructor(public payload: { group: GroupItem<IOGC>, children: ListItem<IOGC>[]}) {}
        }
        
        export class Show {
            static readonly type = '[OGC] Show Group layer';
            constructor(public payload: { group: GroupItem<IOGC>, layer: ListItem<IOGC> }) {}
        }
        
        export class Hide {
            static readonly type = '[OGC] Hide Group layer';
            constructor(public payload: { group: GroupItem<IOGC>, layer: ListItem<IOGC> }) {}
        }
        
        export class SetLoadStatus {
            static readonly type = '[OGC] Set Load Status Group layer';
            constructor(public payload: { layer: ListItem<IOGC>, status: LoadStatus }) {}
        }
        
        export class Focus {
            static readonly type = '[OGC] Focus Group layer';
            constructor(public payload: ListItem<IOGC>) {}
        }

        export namespace Style {
            export class Set {
                static readonly type = '[OGC] Set layer Styles';
                constructor(public payload: { styles: (FeatureFlatStyle | FeatureWebGLStyle)[], layer: ListItem<IOGC> }) {}
            }
    
            export class Add {
                static readonly type = '[OGC] Add layer Style';
                constructor(public payload: { style: FeatureFlatStyle | FeatureWebGLStyle, layer: ListItem<IOGC>, position?: 'start' | 'end' }) {}
            }
    
            export class Modify {
                static readonly type = '[OGC] Modify layer Style';
                constructor(public payload: { style: FeatureFlatStyle | FeatureWebGLStyle, layer: ListItem<IOGC> }) {}
            }
    
            export class Remove {
                static readonly type = '[OGC] Remove layer Style';
                constructor(public payload: { style: FeatureFlatStyle | FeatureWebGLStyle, layer: ListItem<IOGC> }) {}
            }
        }

        export class SetFilterStyles {
            static readonly type = '[OGC] Set layer Filter Styles';
            constructor(public payload: { filters: FilterStyle[], layer: ListItem<IOGC> }) {}
        }
        
        export class AppendLoading {
            static readonly type = '[OGC] Append Loading layer';
            constructor(public payload: { layers: AppendableListItem<IOGC>[] }) {}
        }
        
        export class RemoveLoading {
            static readonly type = '[OGC] Remove Loading layer';
            constructor(public payload: { layers: ListItem<IOGC>[] }) {}
        }
        
        export class AppendUnloading {
            static readonly type = '[OGC] Append Unloading layer';
            constructor(public payload: { layers: ListItem<IOGC>[] }) {}
        }
        
        export class RemoveUnloading {
            static readonly type = '[OGC] Remove Unloading layer';
            constructor(public payload: { layers: ListItem<IOGC>[] }) {}
        }
    }
}

