import { gql } from "apollo-angular";

export const LOGIN = gql`
  query Loggin($userName: String!, $password: String!) {
    logginUser(userName: $userName, password: $password) {
       email
        id
        name
        active,
        personalInfo {
            id
            name
            lastName
            phone
            country
        }
        active
        db {
            id
            dbNameMongo
            dbNamePostgresql
        }
        companyInfo {
            id
            name
            rfc
            postalCode
        }
        aditionalUsers {
            id
            name
            email
            phone
            privilege
            password
        }
        token {
            accessToken
            expiresIn
            idToken
            refreshToken
            tokenType
        }
    }
  }
`;

export const VALITATE_TOKEN = gql`
    query ValidateTokenUser($token: String!) {
        validateTokenUser(token: $token) {
            id
            token
        }
    }
`;

export const REFRESH_TOKEN = gql`
    query RefreshTokenUser($refreshToken: String, $userName: String) {
        refreshTokenUser(RefreshToken: $refreshToken, userName: $userName) {
            id
            token
        }
    }
`;
