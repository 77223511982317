import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../pages/auth-page/graphql/auth.service';
import { Login, Logout, SetUser } from './auth.actions';
import { StateAuthToken } from '../models/auth';
import { Navigate } from '@ngxs/router-plugin';
import { CloseSidebar } from 'src/app/pages/viewer-page/state/sidebar.actions';
import { SetBaseLayer, SetLayers, SetWeatherLayers } from 'src/app/layer-toggle/state/layer.actions';
import { baseLayerMetadataRepository } from 'src/app/layer-toggle/repository/base-layer-repository';
import { Project } from 'src/app/plantation/state/project.actions';
import { Group } from 'src/app/ogc/state/ogc.actions';
import { Styles } from 'src/app/feature-style/state/ogc-feature-style.actions';

export interface AuthStateModel {
  token: StateAuthToken;
  userName: string | null;
  active: boolean;
}

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');


@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: {
    token: null,
    userName: null,
    active: false
  }
})
@Injectable()
export class AuthState {
  @Selector()
  static userName(state: AuthStateModel): string {
    return state.userName;
  }

  @Selector()
  static token(state: AuthStateModel): StateAuthToken {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  constructor(private authService: AuthService) {}

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.authService.login(action.payload).pipe(
      tap((result) => {
        ctx.patchState({
          token: result.token,
          userName: result.userName,
          active: result.active
        });
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>, action: Logout) {
    ctx.setState({
      token: null,
      userName: null,
      active: false
    });

    // Reset all user related states
    // Remove all local storage data related to loaded geometries
    const defaultMap = baseLayerMetadataRepository.find(layer => layer.id === 'roads');
    ctx.dispatch([
      new Project.Set([]),
      new Group.Set([]),
      new SetLayers({ layers: [] }),
      // new Project.Group.Layer.Focus(null),
      // new Group.Layer.Focus(null),
      // new Filters.Set({layer: []}),
      new Styles.Set({layers: []}),
      new SetBaseLayer( { id: defaultMap.id, item: defaultMap, show: true, loaded: 'loaded', parentId: null }  ),
      new SetWeatherLayers([]),
      new CloseSidebar(),
      new Navigate(['/']),
    ])
  }

  @Action(SetUser)
  setUser(ctx: StateContext<AuthStateModel>, { payload }: SetUser) {
    ctx.setState({
      userName: payload.userName,
      token: payload.token,
      active: payload.active
    });
  }
}
